import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RoutesRecognized, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import * as Enums from '../../shared/models/gen.enums';
import * as Auth from '../../shared/models/auth.model';
import { tap, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static sessionKey = "tv.session";
  private static redirectKey = "tv.redirect";
  private session: Auth.SessionInfo;

  public baseUrl: string;

  //showPrivateToggle = false;
  //showPrivateToggleChange: Subject<boolean> = new Subject<boolean>();

  public sessionSubject = new BehaviorSubject<Auth.SessionInfo>(null);

  // These shouldn't have an initial value, so use ReplaySubject instead of BehaviorSubject.
  // Use ReplaySubject instead of Subject so late subscribers will still get a value.
  public routeDataSubject = new ReplaySubject<Data>(1);
  public applicationStatusSubject = new ReplaySubject<Auth.ApplicationStatus>(1);


  constructor(@Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') baseUrl: string)
  {
    this.baseUrl = baseUrl;
    this.initJsonDateParser();
    this.getSession();
    this.initStorageListener();

    //this.showPrivateToggleChange.subscribe((value) => {
    //  this.showPrivateToggle = value;
    //});

    router.events.pipe(filter(event => event instanceof RoutesRecognized))
      .subscribe((event: RoutesRecognized) => {
        const data = event.state.root.firstChild.data;
        this.routeDataSubject.next(data);
      });
  }

  //checkApplicationStatus(): void {
  //  this.http.get<Auth.ApplicationStatus>(
  //    this.baseUrl + 'api/Configuration/ApplicationStatus')
  //    .subscribe(result => {
  //      this.applicationStatusSubject.next(result);
  //      if (result.errors.length > 0)
  //        this.router.navigateByUrl('/users/init-error');
  //      //else if (!result.isActivated)
  //      //  this.router.navigateByUrl('/users/setup');
  //    }, error => console.error(error));

  //}

  login(): void {
    this.http.get(
      this.baseUrl + 'auth/login')
        .subscribe(result => {
        }, error => console.error(error));
}

  getUnauthenticatedSession(): Auth.SessionInfo {
    return {
      IsAuthenticated: false
    };
  }

  clearSession() {
    this.session = this.getUnauthenticatedSession();
    this.sessionSubject.next(this.session);
    localStorage.removeItem(AuthService.sessionKey);
  }

  updateSession(session: Auth.SessionInfo) {
    this.session = session;
    this.saveSession();
  }

  saveSession() {
    this.sessionSubject.next(this.session);
    localStorage.setItem(AuthService.sessionKey, JSON.stringify(this.session));
  }

  private initStorageListener() {
    // Catch any updates to the client session from other tabs and update the session
    // expiration in this tab. This is required so that a dormant tab doesn't expire
    // the client session if another tab is actively being used.
    //const that = this;
    $(window).on("storage", e => {
      if (e.key === AuthService.sessionKey) {
        try {
          const otherSession = JSON.parse(localStorage.getItem(AuthService.sessionKey)) as Auth.SessionInfo;
          this.session.Expiration = otherSession.Expiration;
        } catch {
          // ignore any parsing errors
        }
      }
    });
  }

  getSession(): Auth.SessionInfo {
    if (!this.session) {
      let session = null;
      try {
        session = JSON.parse(localStorage.getItem(AuthService.sessionKey));
      } catch {
        // ignore any parsing errors
      }
      if (!session)
        session = this.getUnauthenticatedSession();
      this.session = session;
      this.sessionSubject.next(this.session);
    }
    return this.session;
  }

  isExpired(session: Auth.SessionInfo): boolean {
    const date = new Date();
    const isExpired = !session || !session.IsAuthenticated || session.Expiration <= date;
    return isExpired;
  }

  resetExpiration(newValue: Date) {
    if (!this.isExpired(this.session)) {
      this.session.Expiration = newValue;
      this.saveSession();
    }
  }

  initJsonDateParser() {
    if (JSON && !JSON["parseWithDate"]) {
      const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(\.\d+)*)(?:Z|(\+|-)([\d|:]*))?$/;

      const dateParser = function (key, value) {
        /// <summary>
        /// Globally enables JSON date parsing for JSON.parse().
        /// Replaces the default JSON.parse() method and adds
        /// the datePaser() extension to the processing chain.
        /// </summary>    
        /// <param name="key" type="string">property name that is parsed</param>
        /// <param name="value" type="any">property value</param>
        /// <returns type="date">returns date or the original value if not a date string</returns>
        if (typeof value === 'string') {
          const a = reISO.exec(value);
          if (a)
            return new Date(value);
          return value;
        }
        return value;
      };

      const _savedParse = JSON.parse;

      JSON.parse = function (json) {
        /// <summary>
        /// Wrapper around the JSON.parse() function that adds a date
        /// filtering extension. Returns all dates as real JavaScript dates.
        /// </summary>    
        /// <param name="json" type="string">JSON to be parsed</param>
        /// <returns type="any">parsed value or object</returns>
        try {
          const res = _savedParse(json, dateParser);
          return res;
        } catch (e) {
          // orignal error thrown has no error message so rethrow with message
          throw new Error("JSON content could not be parsed");
        }
      };

      JSON["parseWithDate"] = true;
    }
  }

  redirectToLogin(url) {
    if (url && url.length > 0)
      localStorage.setItem(AuthService.redirectKey, url);
    else
      localStorage.removeItem(AuthService.redirectKey);
    this.document.location.href = "/auth/login?redirect=" + encodeURIComponent(url);
//    this.router.navigateByUrl('auth/login');
  }

  redirectAfterLogin() {
    let url = localStorage.getItem(AuthService.redirectKey);
    if (!url || url.length === 0)
      url = '/';
    else
      localStorage.removeItem(AuthService.redirectKey);
    this.router.navigateByUrl(url);
  }
}
