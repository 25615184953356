import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotFoundComponent } from './core/components/not-found/not-found.component';

export const APP_ROUTES: Routes = [
  { path: '', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
  { path: 'tags', loadChildren: () => import('./modules/tags/tags.module').then(m => m.TagsModule), canActivate: [AuthGuard] },
  { path: 'categories', loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule), canActivate: [AuthGuard] },
  { path: 'text', loadChildren: () => import('./modules/text/text.module').then(m => m.TextModule), canActivate: [AuthGuard] },
  { path: 'users', loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
  { path: '**', component: NotFoundComponent }
];
