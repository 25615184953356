import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    // Override the reset password link because the token is case-sensitive.
    if (url.toLowerCase().startsWith("/users/reset-password/")) {
      // Lower-case everything except for the last segment
      url = url.split('/').reverse().map((o, i) => i === 0 ? o : o.toLowerCase()).reverse().join("/");
      return super.parse(url);
    }

    // Pass the lower-case version down to the next function.
    return super.parse(url.toLowerCase());
  }
}
