import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Common from '../../shared/models/common.model';
import * as User from '../../shared/models/gen.user.model';
import * as Auth from '../../shared/models/auth.model';
import * as License from '../../shared/models/gen.license.model';
import * as Configuration from '../../shared/models/gen.configuration.model';
import * as Validation from '../../shared/models/gen.validation';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	public baseUrl: string;

	constructor(private http: HttpClient,
		@Inject('BASE_URL') baseUrl: string) {
		this.baseUrl = baseUrl;
	}


	getAboutSummary(): Observable<License.AboutSummary> {
		return this.http.get<License.AboutSummary>(
			this.baseUrl + 'api/Configuration/AboutSummary');
	}

	getSettingsSummary(): Observable<Configuration.SettingsSummary> {
		return this.http.get<Configuration.SettingsSummary>(
			this.baseUrl + 'api/Configuration/SettingsSummary');
	}

  /* Configuration */

  getSmtp(): Observable<Configuration.SmtpDetail> {
    return this.http.get<Configuration.SmtpDetail>(
      this.baseUrl + 'api/Configuration/SmtpDetail');
  }

  saveSmtpDetail(detail: Configuration.SmtpDetail): Observable<Configuration.SmtpSaveResult> {
    return this.http.post<Configuration.SmtpSaveResult>(
      this.baseUrl + 'api/Configuration/SaveSmtpDetail', detail);
  }
}
