import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html'
})
export class AdminPage {
  //view: Home.View;
  isLoaded = true;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    //http.get<Home.View>(baseUrl + 'api/Home/GetView').subscribe(result => {
    //  this.view = result;
    //  this.isLoaded = true;
    //}, error => console.error(error));
  }
}
