import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Common from '../../shared/models/common.model';
import * as Report from '../../shared/models/gen.report.model';
import * as Auth from '../../shared/models/auth.model';
import * as License from '../../shared/models/gen.license.model';
import * as Configuration from '../../shared/models/gen.configuration.model';
import * as Validation from '../../shared/models/gen.validation';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReportType } from '../../shared/models/gen.enums';
import * as Home from '../../shared/models/gen.home.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  public baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getReport(filter: Report.Filter): Observable<Home.ReportUrl> {
    return this.http.post<Home.ReportUrl>(
      this.baseUrl + 'api/Home/GetReportUrl', filter);
  }

  logReportHit(reportIdClass: Common.IdClass): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + 'api/Home/LogReportHit', reportIdClass);
  }

  toggleFavorite(reportIdClass: Common.IdClass): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + 'api/Home/ToggleFavorite', reportIdClass);
  }

  saveConfirmTos(): Observable<boolean> {
    return this.http.post<boolean>(
      this.baseUrl + 'api/Home/ConfirmTos', null);
  }

  updateUserReports(): Observable<Home.UpdateUserReportsResult> {
    return this.http.get<Home.UpdateUserReportsResult>(
      this.baseUrl + 'api/Home/UpdateUserReports');
  }

}
