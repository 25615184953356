import { Component, Input } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';


@Component({
  selector: 'app-field-validation',
  templateUrl: './field-validation.component.html',
  styles: []
})
export class FieldValidationComponent {
  @Input() field: UntypedFormControl;
  @Input() messages: {
    [key: string]: string;
  };

  constructor() {
    this.messages = {
      required: "Field is required"
    };
  }

  getErrors(errors: ValidationErrors) {
    return Object.keys(errors);
  }

  getErrorDisplay(key) {
    // TODO: Add formatting for other validation types.
    if (key === "maxlength") {
      const format = this.messages[key] || "This field has a max length of {0} characters";
      return format.replace(/\{0\}/, this.field.errors[key].requiredLength);
    }
    return this.messages[key] || this.field.errors[key];
  }
}
