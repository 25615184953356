import { Component, Inject, Directive } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../../../core/services/alert.service';
import { ButtonState } from '../../../../shared/models/ui.model';
import * as Grid from '../../../../shared/models/grid.model';
import * as Common from '../../../../shared/models/common.model';
import * as Report from '../../../../shared/models/gen.report.model';
import { ReportService } from '../../../../core/services/report.service';
import { GridService } from '../../../../core/services/grid.service';
import { take } from 'rxjs/operators';

@Directive()
@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.page.html'
})
export class ReportListPage {
  thisThingLCP = "reports";
  thisThingLCS = "report";
  loaded = false;
  deletedBtn: ButtonState;
  grid: Grid.GridInfo<Report.ListItem>;
  selectedRows: Report.Detail[] = [];

  tagTypes: string[] = [];
  tagTypeFilter: string[] = [];
  reportTypes: string[] = [];
  reportTypeFilter: string[] = [];
  yesNoTypes: string[] = ["Yes", "No"];
  isActiveFilter: string[] = [];
  isDeletedFilter: string[] = [];

  applyFilter = this.gridSvc.applyFilter.bind(this.gridSvc);
  applyFilterContains = this.gridSvc.applyFilterContains.bind(this.gridSvc);
  updateFilter = this.gridSvc.updateFilter.bind(this.gridSvc);

  constructor(
    private http: HttpClient,
    private alertSvc: AlertService,
    private reportSvc: ReportService,
    private gridSvc: GridService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject('BASE_URL') private baseUrl: string) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.http.get<Report.List>(this.baseUrl + 'api/Report/GetList')
      .pipe(take(1))
      .subscribe(result => {
      this.grid = new Grid.GridInfo(result.details, {
        sort: [
          { field: 'title', dir: 'asc' }
        ],
        filterPredicate: p => p.isActive,
        activeSettings: { checkboxLabel: "Show reports not found on servers" },
        deletedPredicate: p => p.isDeleted === true,
      });

      this.deletedBtn = this.grid.deletedBtn;

      // Not complete list of possible values, just the values that appear in this list
      this.reportTypes = result.reportTypes;
      this.tagTypes = result.tagTypes;

      this.loaded = true;

      }, error => {
        this.alertSvc.showError("An error occurred while loading the list of "+this.thisThingLCP);
        console.error(error);
      });
  }

  //

  joinNames(array) {
    return Array.prototype.map.call(array, s => s.name).join(", ");
  }

  getLinkUrl(dataItem) {
    return "/reports/" + (dataItem ? dataItem.id : "new");
  }

  newItem() {
    this.router.navigateByUrl(this.getLinkUrl(null));
  }

  openItem() {
    if (this.grid.selectedKeys.length === 1)
      this.router.navigateByUrl(this.getLinkUrl(this.grid.selectedKeys[0]));
  }

  toggleDeleted() {
    if (this.grid.selectedKeys.length === 1) {
      const dataItem = this.grid.selectedKeys[0];

      this.alertSvc.confirmToggleDeleted({
        detail: {
          id: dataItem.id,
          deleted: !dataItem.isDeleted
        },
        confirmOptions: {
          title: "Delete",
          content: "Are you sure you want to delete this " + this.thisThingLCS + "?",
          yesText: "Delete",
        },
        action: (detail: Common.IdDeleted) => {
          this.alertSvc.showSubmitStart(this.deletedBtn);
          this.reportSvc.toggleDeleted(detail)
            .pipe(take(1))
            .subscribe(result => {
            if (result.modelErrors.length > 0) {
              this.alertSvc.showWarning(result.modelErrors[0].description);
              this.grid.processDeletedStatus(this.deletedBtn, false);
            } else {
              dataItem.isDeleted = result.idDeleted.deleted;
              this.grid.processDeletedStatus(this.deletedBtn, true);
            }
            dataItem.isDeletedString = dataItem.isDeleted == true ? "Yes" : "No";
          }, error => {
            this.alertSvc.showSubmitError(this.deletedBtn, "An error occurred while updating the " + this.thisThingLCS);
            console.error(error);
          });
        }
      });
    }
  }

  adminResetReports() {
    this.alertSvc.confirm({
      content: "Are you sure you want to permanently delete all reports in the portal not found on the server (other than the Tableau public reports)?",
    })
      .pipe(take(1))
      .subscribe(result => {
      if (result) {
        this.reportSvc.adminResetReports()
          .pipe(take(1))
          .subscribe(result => {
          if (result.success) {
            this.alertSvc.showInfo("Report reset completed");
            this.load();
          } else {
            this.alertSvc.showError("Error resetting reports");
          }
        })
      }
    })
  }

}
