import { Component, Input, Inject, SecurityContext, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Home from '../../../../shared/models/gen.home.model';
import * as Common from '../../../../shared/models/common.model';
import { HomeService } from '../../../../core/services/home.service';
import { AuthService } from '../../../../core/services/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as Enums from '../../../../shared/models/gen.enums';
import { take } from 'rxjs/operators';

@Directive()
@Component({
  selector: 'app-home',
  templateUrl: './home.page.html'
})
export class HomePage {
  view: Home.View;
  viewAll: Home.View;
  isLoaded = false;

  work: Home.Detail[];
  selectedTag: number = null;
  filterText: string = null;
  sortOptions: { id: number, name: string }[];
  selectedSort: number = 0;
  //filterFavorites: boolean = false;

  isAuthenticated: boolean = false;

  thumbnailDict: Map<number, string> = new Map<number, string>();

  _baseUrl: string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private homeSvc: HomeService,
    private authSvc: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer) {

    this._baseUrl = baseUrl;

    this.sortOptions = [
      { id: 0, name: "Title" },
      { id: 1, name: "Most Popular" },
      //{ id: 2, name: "Recently Updated" }
    ];
  }

  ngOnInit() {
    // Set flag for header
    //var session = this.authSvc.getSession();
    //session.OnPrivatePage = false;
    //session.OnLandingPage = true;
    ////session.OnHomePage = true;
    //this.authSvc.updateSession(session);

    this.cachedLoad();
  }


  loadAll() {

    this.selectedTag = null;

    this.http.get<Home.View>(this._baseUrl + 'api/Home/GetPublicView')
      .pipe(take(1))
      .subscribe(result => {

      this.viewAll = result;

      // Get virtual URLs for images that are coming from the DB

      let url: string = "";
      for (let i of this.viewAll.details) {
        if (i.thumbnail != null) {
          if (i.reportTypeId == Enums.ReportType.SASPrivate) {
            url = 'data:image/svg+xml;base64,' + i.thumbnail;
          } else {
            url = 'data:image/png;base64,' + i.thumbnail;
          }
          i.tempThumbnailUrl = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl(url));
          this.thumbnailDict.set(i.id, i.tempThumbnailUrl);
        }
      };

      // Continue loading and filtering

      this.cachedLoad();

    }, error => console.error(error));
  }

  cachedLoad()
  {
    this.isAuthenticated = this.authSvc.getSession().IsAuthenticated;

    // Cache the list for quick tag filtering

    if (this.viewAll == null) {
      this.loadAll();
      return;
    }

    // Filter by selected tag

    this.view = {
      details: null,
      tags: this.viewAll.tags,
    }

    this.work = this.viewAll.details;

    //if (this.filterFavorites) {
    //  this.work = this.work.filter(o => o.isFavorite);
    //}

    if (this.filterText) {
      this.work = this.work.filter(o => o.searchString.includes(this.filterText.toUpperCase()));
    }

    if (this.selectedTag) {
      this.work = this.work.filter(o => o.tags.map(a => a.id).includes(this.selectedTag));
    }

    // Sort by Popularity
    if (this.selectedSort == 1) {
      this.work.sort((one, two) => (one.totalReportHits > two.totalReportHits) ? -1 : 1);
    }
    // Sort by Last Updated
    else if (this.selectedSort == 2) {
      this.work.sort((one, two) => (one.updatedAt > two.updatedAt) ? -1 : 1);
    }
    // Default: Sort by Title
    else {
      this.work.sort((one, two) => (one.title.toLocaleUpperCase() < two.title.toLocaleUpperCase() ? -1 : 1));
    }

    this.view.details = this.work;

    this.isLoaded = true;
  }

  // Search, Filters, Favorites

  setSelectedTag(value: any) {
    this.selectedTag = value.id;
    this.cachedLoad();
  }

  setFilterText() {
    //this.filterText = value.value.toUpperCase();
    this.cachedLoad();
  }

  setSelectedSort(value: any) {
    this.selectedSort = value.id;
    this.cachedLoad();
  }

  clearFilter() {
    this.selectedTag = null;
    this.filterText = null;
    //this.filterFavorites = false;
    this.cachedLoad();
  }

  toggleFavorite(id: number) {
    var reportIdClass: Common.IdClass = { id: id };
    this.homeSvc.toggleFavorite(reportIdClass)
      .pipe(take(1))
      .subscribe(result => {
      this.view.details.filter(o => o.id == id).forEach(o => o.isFavorite = result);
      // Update report with new favorite status
    }, error => {
      console.error(error);
    });
  }

  //toggleFilterFavorites() {
  //  this.filterFavorites = !this.filterFavorites;
  //  this.cachedLoad();
  //}

  //

  getQueryStringValue(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }


  reportClick(id: number, event) {

    event.preventDefault();

    var thisItem = this.view.details.findIndex(o => o.id == id);

    var reportIdClass: Common.IdClass = { id: id };
    this.homeSvc.logReportHit(reportIdClass)
      .pipe(take(1))
      .subscribe(result => {
    }, error => {
      console.error(error);
    });

    // Lookup type of report
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.TableauPublic) {
      window.open("/tableaupublic/" + id, "_blank");
    }
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.TableauPrivate) {
      window.open("/community/tableauprivate/" + id, "_blank");
    }
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.TableauPrivateStudent) {
      window.open("/community/tableauprivatestudent/" + id, "_blank");
    }
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.SASPrivate) {
      window.open("/community/sasprivate/" + id, "_blank");
    }
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.PDF) {
      window.open("/pdf/" + id, "_blank");
    }

  }

  toggleExpand(event) {
    $(event.target).closest(".card").toggleClass("card-public-expand");
  }
}
