import { Component, Input } from '@angular/core';
import { GridInfo } from '../../../shared/models/grid.model';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
	selector: 'app-grid-header',
	templateUrl: './grid-header.component.html',
	styles: []
})
export class GridHeaderComponent<T> {
  @Input() grid: GridInfo<T>;
  @Input() dataResult: GridDataResult;
}
