import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Common from '../../shared/models/common.model';
import * as Report from '../../shared/models/gen.report.model';
import * as Auth from '../../shared/models/auth.model';
import * as License from '../../shared/models/gen.license.model';
import * as Configuration from '../../shared/models/gen.configuration.model';
import * as Validation from '../../shared/models/gen.validation';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ReportType } from '../../shared/models/gen.enums';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getReport(filter: Report.Filter): Observable<Report.View> {
    return this.http.post<Report.View>(
      this.baseUrl + 'api/Report/Get', filter);
  }

  saveReport(detail: Report.Detail): Observable<Report.SaveResult> {
    return this.http.post<Report.SaveResult>(
      this.baseUrl + 'api/Report/Save', detail);
  }

  addTag(detail: Report.TagDetail): Observable<Report.TagSaveResult> {
    return this.http.post<Report.TagSaveResult>(
      this.baseUrl + 'api/Report/AddTag', detail);
  }

  addCategory(detail: Report.CategoryDetail): Observable<Report.CategorySaveResult> {
    return this.http.post<Report.CategorySaveResult>(
      this.baseUrl + 'api/Report/AddCategory', detail);
  }

  toggleActive(detail: Common.IdActive): Observable<Report.ActiveResult> {
    return this.http.post<Report.ActiveResult>( 
      this.baseUrl + 'api/Report/ToggleActive', detail);
  }

  toggleDeleted(detail: Common.IdDeleted): Observable<Report.DeletedResult> {
    return this.http.post<Report.DeletedResult>( 
      this.baseUrl + 'api/Report/ToggleDeleted', detail);
  }

  adminResetReports(): Observable<Report.AdminResetReportsResult> {
    return this.http.get<Report.AdminResetReportsResult>(
      this.baseUrl + 'api/Report/AdminResetReports');
  }

  getReportModel(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({

      id: [''],

      title: ['', [
        Validators.required,
        Validators.maxLength(Validation.ReportTitle.maxLength)
      ]],

      overrideTitle: ['', [
        Validators.maxLength(Validation.ReportTitle.maxLength)
      ]],

      selectedTags: [''],

      newTag: ['', [
        Validators.maxLength(Validation.TagName.maxLength)
      ]],

      selectedCategories: [''],

      newCategory: ['', [
        Validators.maxLength(Validation.CategoryName.maxLength)
      ]],

      description: ['', [
        Validators.required,
        Validators.maxLength(Validation.ReportDescription.maxLength)
      ]],

      overrideDescription: ['', [
        Validators.maxLength(Validation.ReportDescription.maxLength)
      ]],

      reportTypeId: ['', [
        Validators.required
      ]],

      thumbnailUrl: ['', [
        Validators.maxLength(Validation.ReportThumbnailUrl.maxLength)
      ]],

      url: ['', [
        Validators.required,
        Validators.maxLength(Validation.ReportUrl.maxLength)
      ]],

      showTabs: [''],

      overrideUrl: [''],

      isActive: [''],
      isDeleted: ['']

    });
  }

  static validationMsg = {
    title: {
      required: Validation.ReportTitle.requiredMessage,
      maxlength: Validation.ReportTitle.maxLengthMessage
    },
    overrideTitle: {
      maxlength: Validation.ReportTitle.maxLengthMessage
    },
    newTag: {},
    newCategory: {},
    description: {
      required: Validation.ReportDescription.requiredMessage,
      maxlength: Validation.ReportDescription.maxLengthMessage
    },
    overrideDescription: {
      maxlength: Validation.ReportDescription.maxLengthMessage
    },
    reportType: {
      required: Validation.ReportType.requiredMessage,
    },
    thumbnailUrl: {
      maxlength: Validation.ReportThumbnailUrl.maxLengthMessage
    },
    url: {
      required: Validation.ReportUrl.requiredMessage,
      maxlength: Validation.ReportUrl.maxLengthMessage
    },
    overrideUrl: {
      maxlength: Validation.ReportUrl.maxLengthMessage
    },
    isActive: {
      required: Validation.ReportIsActive.requiredMessage,
    }
  }; 

}
