import { Component, ViewChild, ElementRef, OnInit, Directive } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HomeService } from '../../../../core/services/home.service';
import { AlertService } from '../../../../core/services/alert.service';
import { UntypedFormBuilder } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as Home from '../../../../shared/models/gen.home.model';
import * as Report from '../../../../shared/models/gen.report.model';
import { AuthService } from '../../../../core/services/auth.service';

//declare var tableau: any;

@Directive()
@Component({
  selector: 'app-sasprivate',
  templateUrl: './sasprivate.page.html'
})
export class SasPrivatePage implements OnInit {
  @ViewChild("loadingMessage") loadingMessage: ElementRef;
  @ViewChild("sas-report") sasReport: ElementRef;

  thisThingLCS = "workbook";

  routeInfo: {
    id: string;
  };

  _baseUrl: string;

  view: Home.ReportUrl;

  loaded = false;
  error = false;

  constructor(private fb: UntypedFormBuilder,
    private authSvc: AuthService,
    private homeSvc: HomeService,
    private alertSvc: AlertService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    // Set flag for header
    //var session = this.authSvc.getSession();
    //session.OnPrivatePage = true;
    //session.OnLandingPage = false;
    //this.authSvc.updateSession(session);

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of({
        id: params.get('id')
      }))
    )
      .pipe(take(1))
      .subscribe(info => {

      this.routeInfo = info;
      const filter: Report.Filter = {
        id: info.id
      };
        this.homeSvc.getReport(filter)
          .pipe(take(1))
          .subscribe(result => {
        this.view = result;
        this.loaded = true; 

      }, error => {
        this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
        this.error = true;
        console.error(error);
      });

    }, error => {
      this.alertSvc.showError("An error occurred reading the parameters while loading the " + this.thisThingLCS);
      this.error = true;
      console.error(error);
    });

  }

}
