import { Component, OnInit, HostListener, Directive, OnDestroy } from '@angular/core'; 
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms';

import { of, Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AuthService } from '../../../../core/services/auth.service';
import { AlertService } from '../../../../core/services/alert.service';
import { ButtonState } from '../../../../shared/models/ui.model';

import * as Common from '../../../../shared/models/common.model';
import * as Enums from '../../../../shared/models/gen.enums';
import * as Model from '../../../../shared/models/gen.category.model';
import { CategoryService } from '../../../../core/services/category.service';


@Directive()
@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.page.html'
})
export class CategoryEditPage implements OnInit, OnDestroy, Common.CanComponentDeactivate {

  thisThingLCS = "category";
  subscription = new Subscription();

  saveBtn: ButtonState = {
    iconClass: "fad fa-save"
  };
  runBtn: ButtonState = {
    iconClass: "fad fa-play"
  };
  deleteBtn: ButtonState = {
    iconClass: "fad fa-trash"
  };

  isReadOnly = false;

  model: UntypedFormGroup;
  view: Model.View;
  detail: Model.Detail;
  saveResult: Model.SaveResult;

  loaded = false;

  routeInfo: {
    id: string;
  };

  isNew: boolean;

  validationMsg = CategoryService.validationMsg;

  saveMsg = {
    invalid: "Please fix the errors on this page before saving",
    success: "The " + this.thisThingLCS + " was saved successfully",
    error: "An error occurred while saving the " + this.thisThingLCS
  };

  constructor(private fb: UntypedFormBuilder,
    private authSvc: AuthService,
    private svc: CategoryService,
    private alertSvc: AlertService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.subscription = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of({
        id: params.get('id')
      }))
    ).subscribe(info => {
      this.routeInfo = info;

      const filter: Model.Filter = {
        id: info.id
      };
      this.svc.getCategory(filter)
        .pipe(take(1))
        .subscribe(result => {
        this.view = result;
        this.detail = result.detail;

        // If we're making a copy, update the detail using the prior values.
        if (history.state.copyDetail) {
          this.detail = $.extend(true, {}, history.state.copyDetail);
          this.detail.id = null;
          if (this.detail.name)
            this.detail.name = this.detail.name + " - Copy";
        }

        this.isNew = this.detail.id === null;

        this.model = this.svc.getCategoryModel(this.fb);
        this.model.patchValue(this.detail);

        // If this is a copy, mark the form as dirty so the Save button is enabled.
        if (history.state.copyDetail)
          this.model.markAsDirty();

        this.loaded = true;

      }, error => {
        this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
        console.error(error);
      });

    }, error => {
      this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
      console.error(error);
    });

  }
  //

  get f() { return this.model.controls; }

  //

  save() {
    this.model.markAllAsTouched();

    if (!this.model.valid) {
      this.alertSvc.showSubmitInvalid(this.saveBtn, this.saveMsg.invalid);
      return;
    }

    this.alertSvc.showSubmitStart(this.saveBtn);

    this.svc.saveCategory(this.model.value)
      .pipe(take(1))
      .subscribe(result => {
      if (!this.alertSvc.processModelErrors(result.modelErrors, this.model, this.saveBtn, this.saveMsg.invalid)) {
        this.model.markAsPristine();
        this.router.navigateByUrl("/categories");
      }
    }, error => {
      this.alertSvc.showSubmitError(this.saveBtn, this.saveMsg.error);
      console.error(error);
    });
  }

  copy() {
    const extras = {
      state: {
        copyDetail: this.model.value
      }
    };
    this.router.navigateByUrl("/categories/new", extras);
  }

  delete() {
    const detail: Model.DeletionDetail = {
      ids: [this.detail.id]
    }

    this.alertSvc.confirmDelete({
      detail: detail,
      singularLower: "category",
      singularUpper: "Category",
      pluralLower: "categories",
      pluralUpper: "Categories",
      action: (successMessage: string, errorMessage: string) => {
        this.alertSvc.showSubmitStart(this.deleteBtn);
        this.svc.deleteCategories(detail)
          .pipe(take(1))
          .subscribe(result => {
          if (result) {
            this.router.navigateByUrl("/categories");
          } else {
            this.alertSvc.showSubmitInvalid(this.deleteBtn, errorMessage);
          }
        }, error => {
          this.alertSvc.showSubmitError(this.deleteBtn, errorMessage);
          console.error(error);
        });
      }
    });

  }

  cancel() {
    this.router.navigateByUrl("/categories");
  }

  //

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.model.dirty)
      return true;
    return this.alertSvc.confirm({
      title: "Leave?",
      content: "Do you want to leave without saving your changes?"
    });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(e) {
    if (this.model.dirty)
      e.returnValue = "Are you sure you want to leave?";
  }
}
