import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';

import { CategoryListPage } from './pages/category-list/category-list.page';
import { CategoryEditPage } from './pages/category-edit/category-edit.page';

const MODULE_ROUTES: Routes = [
  {
    path: 'categories/:id',
    component: CategoryEditPage,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }, {
    path: 'categories',
    component: CategoryListPage,
    canActivate: [AuthGuard]
  }
];

@NgModule({
	declarations: [
    CategoryListPage,
    CategoryEditPage
	],
	imports: [
		CommonModule,
		RouterModule.forChild(MODULE_ROUTES),
		SharedModule
	],
  exports: [
    CategoryListPage,
    CategoryEditPage
	]
})
export class CategoriesModule { } 
