import { Component, ViewChild, Input, Inject, SecurityContext, AfterViewInit, ElementRef, Directive } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HomeService } from '../../../../core/services/home.service';
import { AlertService } from '../../../../core/services/alert.service';
import { UntypedFormBuilder } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as Home from '../../../../shared/models/gen.home.model';
import * as Report from '../../../../shared/models/gen.report.model';
import { AuthService } from '../../../../core/services/auth.service';

declare var tableau: any;

@Directive()
@Component({
  selector: 'app-tableauprivate',
  templateUrl: './tableauprivate.page.html'
})
export class TableauPrivatePage implements AfterViewInit {
  viz: any;
  @ViewChild("loadingMessage") loadingMessage: ElementRef;
  @ViewChild("vizContainer") containerDiv: ElementRef;

  thisThingLCS = "workbook";

  routeInfo: {
    id: string;
  };

  _baseUrl: string;

  view: Home.ReportUrl;

  loaded = false;

  constructor(private fb: UntypedFormBuilder,
    private homeSvc: HomeService,
    private alertSvc: AlertService,
    private authSvc: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    // Set flag for header
  //  var session = this.authSvc.getSession();
  //  session.OnPrivatePage = true;
  //  session.OnLandingPage = false;
  //  this.authSvc.updateSession(session);
  }

  ngAfterViewInit() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of({
        id: params.get('id')
      }))
    )
      .pipe(take(1))
      .subscribe(info => {
      this.routeInfo = info;
      const filter: Report.Filter = {
        id: info.id
      };
        this.homeSvc.getReport(filter)
          .pipe(take(1))
          .subscribe(result => {
        this.view = result;

        var url = this.view.server + "/views/" + this.view.url + "?:embed=y&:toolbar=top";
        if (this.view.showTabs) {
          url = url + "&:tabs=yes"
        } else {
          url = url + "&:tabs=no"
        }
        
        var divWidth = this.containerDiv.nativeElement.offsetWidth;
        var displayDevice;

        if (divWidth <= 500) {
          displayDevice = "phone";
        } else if (divWidth <= 800) {
          displayDevice = "tablet";
        } else {
          displayDevice = "desktop";
        }

        var options = {
          device: displayDevice,
          onFirstInteractive: function () {
            // The viz is now ready and can be safely used.
            $("#loadingMessage").remove();
          }
        };

        // Creating a viz object and embed it in the container div.
        this.viz = new tableau.Viz(
          this.containerDiv.nativeElement, url, options);

        //$(function () {
        //  var url = result.server;
        //  $.ajax(url,
        //    {
        //      method: 'GET',
        //      headers: {
        //        "accept": "application/json",
        //        "Access-Control-Allow-Origin": "*"
        //      }
        //    })
        //    .fail(function (response) {
        //      $(".tableau-container").hide();
        //      $(".errormessage").html("<p>We're having trouble displaying this report. Please make sure your VPN is connected, then refresh this window.</p>");
        //    });
        //});

        this.loaded = true;

      }, error => {
        this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
        console.error(error);
      });

    }, error => {
      this.alertSvc.showError("An error occurred reading the parameters while loading the " + this.thisThingLCS);
      console.error(error);
    });

  }

}
