import { Component, ViewChild, Input, Inject, SecurityContext, AfterViewInit, ElementRef, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as Home from '../../../../shared/models/gen.home.model';
import * as Common from '../../../../shared/models/common.model';
import { AuthService } from '../../../../core/services/auth.service';
import { HomeService } from '../../../../core/services/home.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ReportService } from '../../../../core/services/report.service';
import { AlertService } from '../../../../core/services/alert.service';
import { UntypedFormBuilder } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as Report from '../../../../shared/models/gen.report.model';

declare var tableau: any;

@Directive()
@Component({
  selector: 'app-tableau-public',
  templateUrl: './tableaupublic.page.html'
})
export class TableauPublicPage implements AfterViewInit {
  viz: any;
  @ViewChild("loadingMessage") loadingMessage: ElementRef;
  @ViewChild("vizContainer") containerDiv: ElementRef;

  thisThingLCS = "workbook";

  routeInfo: {
    id: string;
  };

  _baseUrl: string;

  view: Home.ReportUrl;

  loaded = false;
  error = false;

  showReportAid = true;

  constructor(private fb: UntypedFormBuilder,
    private authSvc: AuthService,
    private homeSvc: HomeService,
    private reportSvc: ReportService,
    private alertSvc: AlertService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    // Set flag for header
  //  var session = this.authSvc.getSession();
  //  session.OnPrivatePage = false;
  //  session.OnLandingPage = false;
  //  this.authSvc.updateSession(session);
  }

  ngAfterViewInit() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of({
        id: params.get('id')
      }))
    )
      .pipe(take(1))
      .subscribe(info => {
      this.routeInfo = info;

      const filter: Report.Filter = {
        id: info.id
      };
        this.homeSvc.getReport(filter)
          .pipe(take(1))
          .subscribe(result => {
        this.view = result;

        var url = this.view.server + "/views/" + this.view.url + "?:embed=yes";
        if (this.view.showTabs) {
          url = url + "&:tabs=yes"
        } else {
          url = url + "&:tabs=no"
        }

        var divWidth = this.containerDiv.nativeElement.offsetWidth;
        var displayDevice;

        if (divWidth <= 500) {
          displayDevice = "phone";
        } else if (divWidth <= 800) {
          displayDevice = "tablet";
        } else {
          displayDevice = "desktop";
        }

        var options = {
          device: displayDevice,
          onFirstInteractive: function () {
            // The viz is now ready and can be safely used.
            $("#loadingMessage").remove();
          }
        };

        // Creating a viz object and embed it in the container div.
        this.viz = new tableau.Viz(
          this.containerDiv.nativeElement, url, options);

        this.loaded = true;

      }, error => {
        this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
        console.error(error);
      });

    }, error => {
      this.alertSvc.showError("An error occurred reading the parameters while loading the " + this.thisThingLCS);
      console.error(error);
    });

  }

  getQueryStringValue(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  openReportAid() {
    this.showReportAid = true;
  }

  closeReportAid() {
    this.showReportAid = false;
  }
}
