import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../../../core/services/alert.service';
import { ButtonState } from '../../../../shared/models/ui.model';
import * as Grid from '../../../../shared/models/grid.model';
import * as Common from '../../../../shared/models/common.model';
import * as User from '../../../../shared/models/gen.user.model';
import { UserService } from '../../../../core/services/user.service';
import { GridService } from '../../../../core/services/grid.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.page.html'
})
export class UserListPage {
  thisThingLCS = "user";
  thisThingLCP = "users";
  loaded = false;
  activeBtn: ButtonState;
  grid: Grid.GridInfo<User.ListItem>;
  selectedRows: User.Detail[] = [];

  yesNoTypes: string[] = ["Yes", "No"];
  isActiveFilter: string[] = [];
  isAdminFilter: string[] = [];

  applyFilter = this.gridSvc.applyFilter.bind(this.gridSvc);
  applyFilterContains = this.gridSvc.applyFilterContains.bind(this.gridSvc);
  updateFilter = this.gridSvc.updateFilter.bind(this.gridSvc);

constructor(
    http: HttpClient, 
    private alertSvc: AlertService,
    private userSvc: UserService,
    private gridSvc: GridService, 
    private router: Router,
    private route: ActivatedRoute,
    @Inject('BASE_URL') baseUrl: string)
  {

  http.get<User.List>(baseUrl + 'api/User/GetList')
    .pipe(take(1))
    .subscribe(result => {
      this.grid = new Grid.GridInfo(result.details, {
        sort: [
          { field: 'userName', dir: 'asc' }
        ],
        activePredicate: p => p.isActive
      });

      this.activeBtn = this.grid.activeBtn;

      this.loaded = true;

      }, error => {
        this.alertSvc.showError("An error occurred while loading the list of " + this.thisThingLCP);
        console.error(error);
      });
  }

  //

  joinNames(array) {
    return Array.prototype.map.call(array, s => s.name).join(", ");
  }

  getLinkUrl(dataItem) {
    return "/users/" + (dataItem ? dataItem.id : "new");
  }

  //newItem() {
  //  this.router.navigateByUrl(this.getLinkUrl(null));
  //}

  openItem() {
    if (this.grid.selectedKeys.length === 1)
      this.router.navigateByUrl(this.getLinkUrl(this.grid.selectedKeys[0]));
  }

  toggleActive() {
    if (this.grid.selectedKeys.length === 1) {
      const dataItem = this.grid.selectedKeys[0];

      this.alertSvc.confirmToggleActive({
        detail: {
          id: dataItem.id,
          active: !dataItem.isActive
        },
        confirmOptions: {
          title: "Deactivate",
          content: "Are you sure you want to deactivate this " + this.thisThingLCS + "?",
          yesText: "Deactivate",
        },
        action: (detail: Common.IdActive) => {
          this.alertSvc.showSubmitStart(this.activeBtn);
          this.userSvc.toggleActive(detail)
            .pipe(take(1))
            .subscribe(result => {
            if (result.modelErrors.length > 0) {
              this.alertSvc.showWarning(result.modelErrors[0].description);
              this.grid.processActiveStatus(this.activeBtn, false);
            } else {
              dataItem.isActive = result.idActive.active;
              dataItem.isActiveAsString = result.idActive.active ? 'Yes' : 'No';
              this.grid.processActiveStatus(this.activeBtn, true);
            }
          }, error => {
            this.alertSvc.showSubmitError(this.activeBtn, "An error occurred while updating the " + this.thisThingLCS);
            console.error(error);
          });
        }
      });
    }
  }

}
