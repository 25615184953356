import { Component, forwardRef, ViewChild, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { ToolBarToolComponent, DisplayMode } from '@progress/kendo-angular-toolbar';
import { SplitButtonComponent, Button } from '@progress/kendo-angular-buttons';
import { UntypedFormGroup } from '@angular/forms';
import { Keys } from '@progress/kendo-angular-common';
import { AlertService } from '../../../core/services/alert.service';


@Component({
  // tslint:disable-next-line:no-forward-ref
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolSplitButtonComponent) }],
  selector: 'app-tool-split-button',
  templateUrl: './tool-split-button.component.html',
  styles: []
})
export class ToolSplitButtonComponent extends ToolBarToolComponent {
  @Input() showText: DisplayMode = 'both';
  @Input() showIcon: DisplayMode = 'both';
  @Input() text = "";
  @Input() className = "";
  @Input() icon = "";
  @Input() iconClass = "";
  @Input() disabled = false;
  @Input() disableWhenPristine = false;
  @Input() grayIconWhenPristine = false;
  @Input() model: UntypedFormGroup;
  @Input() textField = "text";
  @Input() data: any[];

  @ViewChild('toolbarTemplate', { static: true }) public toolbarTemplate: TemplateRef<any>;
  @ViewChild('toolbarSplitButton') public toolbarSplitButton: SplitButtonComponent;

  @Output() buttonClick = new EventEmitter();
  @Output() itemClick = new EventEmitter();

  constructor(private alertSvc: AlertService) {
    super();
  }

  canFocus() {
    return !this.disabled;
  }

  focus(ev) {
    // guard against focusing twice on mousedown.
    if (!ev || ev.type === 'focus' || ev.type === 'keydown') {
      // focus the focusable element
      this.toolbarSplitButton.focus();
    }

    // manage the roving tabindex: see https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex
    this.toolbarSplitButton.tabIndex = 0;
  }

  handleKey(ev) {
    if (ev.keyCode === Keys.ArrowLeft || ev.keyCode === Keys.ArrowRight) {
    // manage the roving tabindex: see https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex
      this.toolbarSplitButton.tabIndex = -1;
      this.toolbarSplitButton.blur();
    }
    return false;
  }

  isDisabled(): boolean {
    return this.disabled || (this.disableWhenPristine && this.model?.pristine);
  }

  getClass() {
    if (this.toolbarSplitButton && this.toolbarSplitButton.button) {
      const buttonDisabled = this.isDisabled() || !this.grayIconWhenPristine || this.model?.pristine;
      $(this.toolbarSplitButton.button.nativeElement).prop("disabled", buttonDisabled);
    }

    const state = this.alertSvc.getButtonStateClass(this.model);
    return !this.className ? state : [state, this.className].join(" ");
  }
}
