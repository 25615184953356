import { SortDescriptor, orderBy, CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { SortSettings, RowClassArgs, CellClickEvent } from "@progress/kendo-angular-grid";
import { AlertService } from '../../core/services/alert.service';
import { UntypedFormControl } from "@angular/forms";
import { ButtonState, ActiveSettings } from "./ui.model";

export interface GridInit<T> {
  sort: SortDescriptor[];
  filterPredicate: (o: T) => boolean;
  activePredicate: (o: T) => boolean;
  deletedPredicate: (o: T) => boolean;
  state: ButtonState;
  includeInactive: boolean;
  activeSettings: ActiveSettings;
  onSelectedKeysChanged: (o: T[]) => void;
  take: number;
  selectAll: {
    getKey: (dataItem: T) => string;
    getDataSelected: (dataItem: T) => boolean;
    setDataSelected: (dataItem: T, selected: boolean) => void;
  };
}

export class GridInfo<T> {
  private static activeSettings: ActiveSettings = {
    checkboxLabel: "Include deleted",
    btnLabel: {
      inactive: "Undelete",
      active: "Delete"
    },
    btnIconClass: {
      inactive: "fad fa-eye-slash",
      active: "fad fa-eye"
    }
  };

  allData: T[];
  filteredData: T[];
  selectedKeys: T[];
  totalCount: number;
  filteredCount: number;
  includeInactive: boolean;
  activeSettings: ActiveSettings;
  deletedSettings: ActiveSettings;
  skip: number;
  take: number;
  sort: SortDescriptor[];
  filter?: CompositeFilterDescriptor;
  filterPredicate?: (o: T) => boolean;
  activePredicate?: (o: T) => boolean;
  deletedPredicate?: (o: T) => boolean;
  onSelectedKeysChanged: (o: T[]) => void;
  sortable: SortSettings = {
    allowUnsort: true,
    mode: "multiple"
  };
  toolActions = {
    single: false,
    none: true
  }
  activeBtn: ButtonState;
  deletedBtn: ButtonState;
  selectAll: {
    getKey: (dataItem: T) => string;
    getDataSelected: (dataItem: T) => boolean;
    setDataSelected: (dataItem: T, selected: boolean) => void;
    state: {
      checked: boolean;
      indeterminate: boolean;
      selected: number;
    };
  }

  constructor(allData: T[], init?: Partial<GridInit<T>>) {
    this.allData = allData;
    this.totalCount = allData.length;
    this.sort = init?.sort || [];
    this.selectedKeys = [];
    this.onSelectedKeysChanged = init?.onSelectedKeysChanged;
    this.filterPredicate = init?.filterPredicate;
    this.activePredicate = init?.activePredicate || init?.filterPredicate;
    this.deletedPredicate = init?.deletedPredicate;
    this.includeInactive = init?.includeInactive || false;
    this.activeSettings = $.extend(true, {}, GridInfo.activeSettings, init.activeSettings);
    this.deletedSettings = $.extend(true, {}, GridInfo.activeSettings, init.activeSettings);
    this.take = init?.take;

    this.activeBtn = init.state || {
      text: this.activeSettings.btnLabel.inactive,
      iconClass: this.activeSettings.btnIconClass.inactive,
    };

    this.deletedBtn = init.state || {
      text: this.deletedSettings.btnLabel.inactive,
      iconClass: this.deletedSettings.btnIconClass.inactive,
    };

    if (init.selectAll) {
      this.selectAll = {
        getKey: init?.selectAll?.getKey,
        getDataSelected: init?.selectAll?.getDataSelected,
        setDataSelected: init?.selectAll?.setDataSelected,
        state: {
          checked: false,
          indeterminate: false,
          selected: 0
        }
      }
    }

    this.processData(true, false);
  }

  public changeSort(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.processData(false, false);
  }

  public changeFilter(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.processData(false, true);
  }

  public toggleActive() {
    this.includeInactive = !this.includeInactive;
    this.processData(false, true);
  }

  public addItem(item: T, select: boolean) {
    this.allData.push(item);
    this.processData(false, true);

    if (select) {
      this.selectedKeys = [item];
      this.selectedKeysChanged();
    }
  }

  public removeItems(items: T[]) {
    items.forEach(item => {
      const index = this.allData.map(o => o["id"]).indexOf(item["id"]);
      //const index = this.allData.indexOf(item);
      if (index >= 0)
        this.allData.splice(index, 1);
    });
    this.processData(false, true);

    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
      this.selectedKeysChanged();
    }
  }

  public resetItems(items: T[]) {
    this.allData = items;
    this.processData(false, true);

    if (this.selectedKeys.length > 0) {
      this.selectedKeys = [];
      this.selectedKeysChanged();
    }
  }

  private processData(init: boolean, updateCount: boolean) {
    let data = this.includeInactive || !this.filterPredicate
      ? this.allData : $.grep(this.allData, this.filterPredicate);
    if (this.filter)
      data = filterBy(data, this.filter);

    if (updateCount)
      this.totalCount = this.allData.length;

    this.filteredData = orderBy(data, this.sort);
    this.filteredCount = data.length;
    this.selectedKeys = [];

    if (!init)
      this.selectedKeysChanged();

    if (this.selectAll && (init || updateCount))
      this.updateSelectAllState(null);
  }

  public selectedKeysChanged() {
    this.cancelActiveRevert();
    this.toolActions.none = this.selectedKeys.length === 0;
    this.toolActions.single = this.selectedKeys.length === 1;
    if (this.onSelectedKeysChanged)
      this.onSelectedKeysChanged(this.selectedKeys);
    this.updateActiveButton(true);
    this.updateDeletedButton(true);
  }

  public setSelectedKeys(keys: T[]) {
    this.selectedKeys = keys;
    this.selectedKeysChanged();
  }

  public selectedCallback(args) {
    return args.dataItem;
  }

  // Use => to define the callback so the "this" parameter is correct.
  public rowCallback = (context: RowClassArgs) => {
    return {
      inactive: this.activePredicate && !this.activePredicate(context.dataItem),
      deleted: this.deletedPredicate && this.deletedPredicate(context.dataItem)
    };
  }

  public selectCellClick(control: UntypedFormControl, event: CellClickEvent) {
    if (event.type === "click")
      this.toggleItem(control, event.dataItem);
  }

  public processActiveStatus(btnState: ButtonState, success: boolean) {
    const newClass = this.updateActiveButton(false);

    btnState.disabled = false;
    this.activeBtn.iconClass = "fad " +
      (success ? AlertService.saveIcons.success : AlertService.saveIcons.invalid);
    if (this.activeBtn.timeoutId)
      clearTimeout(this.activeBtn.timeoutId);
    this.activeBtn.timeoutId = setTimeout(() => {
      this.activeBtn.timeoutId = 0;
      this.activeBtn.iconClass = newClass;
    }, AlertService.revertDelay) as unknown as number;
  }

  public processDeletedStatus(btnState: ButtonState, success: boolean) {
    const newClass = this.updateDeletedButton(false);

    btnState.disabled = false;
    this.deletedBtn.iconClass = "fad " +
      (success ? AlertService.saveIcons.success : AlertService.saveIcons.invalid);
    if (this.deletedBtn.timeoutId)
      clearTimeout(this.deletedBtn.timeoutId);
    this.deletedBtn.timeoutId = setTimeout(() => {
      this.deletedBtn.timeoutId = 0;
      this.deletedBtn.iconClass = newClass;
    }, AlertService.revertDelay) as unknown as number;
  }

  private cancelActiveRevert() {
    if (this.activeBtn.timeoutId) {
      clearTimeout(this.activeBtn.timeoutId);
      this.activeBtn.timeoutId = 0;
    }
  }

  private updateActiveButton(updateIcon) {
    if (!this.activeBtn || !this.activePredicate)
      return;

    const restore = this.toolActions.single && !this.activePredicate(this.selectedKeys[0]);
    const newClass = restore ? this.activeSettings.btnIconClass.active
      : this.activeSettings.btnIconClass.inactive;
    this.activeBtn.text = restore ? this.activeSettings.btnLabel.active
      : this.activeSettings.btnLabel.inactive;
    if (updateIcon)
      this.setDeleteButtonClass(newClass);
    return newClass;
  }

  private updateDeletedButton(updateIcon) {
    if (!this.deletedBtn || !this.deletedPredicate)
      return;

    const restore = this.toolActions.single && !this.deletedPredicate(this.selectedKeys[0]);
    const newClass = restore ? this.deletedSettings.btnIconClass.active
      : this.deletedSettings.btnIconClass.inactive;
    this.deletedBtn.text = restore ? this.deletedSettings.btnLabel.active
      : this.deletedSettings.btnLabel.inactive;
    if (updateIcon)
      this.setDeleteButtonClass(newClass);
    return newClass;
  }

  private setDeleteButtonClass(newClass: string) {
    // Set the class to an empty string first to force an update.
    this.deletedBtn.iconClass = "";
    this.deletedBtn.iconClass = newClass;
  }

  public toggleAll(control: UntypedFormControl, event) {
    if (!this.selectAll)
      return;

    const checked = $(event.currentTarget).prop("checked");
    $.each(this.filteredData, (i, d) => {
      this.selectAll.setDataSelected(d, checked);
    });
    this.updateSelectAllState(control);
  }

  public toggleItem(control: UntypedFormControl, dataItem: T) {
    if (!this.selectAll)
      return;

    this.selectAll.setDataSelected(dataItem, !this.selectAll.getDataSelected(dataItem));
    this.updateSelectAllState(control);
  }

  public updateSelectAllState(control: UntypedFormControl) {
    if (!this.selectAll)
      return;

    const keys = $.map(this.filteredData,
      d => this.selectAll.getDataSelected(d) ? this.selectAll.getKey(d) : null);
    const all = this.filteredData.length;
    const selected = keys.length;
    this.selectAll.state.checked = all === selected;
    this.selectAll.state.indeterminate = selected > 0 && selected < all;
    this.selectAll.state.selected = selected;

    if (control) {
      // Look at all records to find selected keys, not just the filtered records.
      const allKeys = $.map(this.allData,
        d => this.selectAll.getDataSelected(d) ? this.selectAll.getKey(d) : null);
      control.setValue(allKeys);
      control.markAsDirty();
    }
  }
}
