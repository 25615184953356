import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import * as Common from '../../shared/models/common.model';

@Injectable({
  providedIn: "root"
})
export class CanDeactivateGuard implements CanDeactivate<Common.CanComponentDeactivate> {
  canDeactivate(component: Common.CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
