import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "../../core/services/auth.service";

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  private static sessionExpHeaderKey = "x-tv-session-exp";

  constructor(private authSvc: AuthService) {
    this.initializeAjaxComplete();
  }

  initializeAjaxComplete() {
    // This will catch AJAX requests triggered outside of Angular.
    $(document).ajaxComplete((event, xhr, options) => {
      this.processResponse(xhr.responseJSON,
        xhr.getResponseHeader(SessionInterceptor.sessionExpHeaderKey));
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // This will catch any requests sent from Angular, but does not catch
    // AJAX requests triggered outside of Angular.

    //if (req.url.startsWith("https://sasreports.umd.edu/SASLogon/")) {
    //  req = req.clone({
    //    //withCredentials: true, //not needed anymore
    //    setHeaders: {
    //      'Content-Type': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
    //      //'API-TOKEN': 'xxx'
    //    },
    //  });
    //}

    return next.handle(req).pipe(
      tap((response: any) => {
        if (response.headers)
          this.processResponse(response.body,
            response.headers.get(SessionInterceptor.sessionExpHeaderKey));
      }));
  }

  processResponse(bodyJson, headerValue) {
    if (headerValue) {
      const newExpiration = new Date(headerValue) as any;
      if (!isNaN(newExpiration))
        this.authSvc.resetExpiration(newExpiration);
    }
    if (bodyJson && bodyJson.currentUserContext) {
      const userContext = bodyJson.currentUserContext;
      if (userContext.session && userContext.session.isAuthenticated)
        this.authSvc.updateSession(userContext.session);
    }
  }
}
