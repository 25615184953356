import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { GridModule } from '@progress/kendo-angular-grid';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DateInputsModule, DatePickerModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { CheckBoxModule } from '@progress/kendo-angular-inputs';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { GridHeaderComponent } from './components/grid-header/grid-header.component';
import { ToolButtonComponent } from './components/tool-button/tool-button.component';
import { ToolSplitButtonComponent } from './components/tool-split-button/tool-split-button.component';
import { RequiredFieldComponent } from './components/required-field/required-field.component';
import { FieldValidationComponent } from './components/field-validation/field-validation.component';
import { PopupComponent } from './components/popup/popup.component';
import { MultiCheckListComponent } from './components/multi-check-list/multi-check-list.component';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { ColumnDateComponent } from './components/column-date/column-date.component';

import { AutofocusDirective } from './directives/autofocus.directive';
import { TrimDirective } from './directives/trim.directive';


@NgModule({
  declarations: [
    GridHeaderComponent,
    ToolButtonComponent,
    ToolSplitButtonComponent,
    RequiredFieldComponent,
    FieldValidationComponent,
    PopupComponent,
    MultiCheckListComponent,
    LayoutHeaderComponent,
    ColumnDateComponent,
    AutofocusDirective,
    TrimDirective
  ],
  imports: [
    RouterModule,
    TabStripModule,
    ToolBarModule,
    DropDownListModule,
    TooltipModule,
    DialogsModule,
    DatePickerModule,
    TimePickerModule,
    ButtonsModule,
    CheckBoxModule,
    GridModule,
    PopupModule,
    TreeViewModule,
    MultiSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TextBoxModule,
    LabelModule,
    InputsModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TextBoxModule,
   LayoutModule,
    TreeViewModule,
    GridModule,
    MultiSelectModule,
    ToolBarModule,
    DropDownListModule,
    ButtonsModule,
    NotificationModule,
    DialogsModule,
    TabStripModule,
    PopupModule,
    TooltipModule,
    DateInputsModule,
    DatePickerModule,
    TimePickerModule,
    CheckBoxModule,
    GridHeaderComponent,
    ToolButtonComponent,
    ToolSplitButtonComponent,
    RequiredFieldComponent,
    FieldValidationComponent,
    PopupComponent,
    MultiCheckListComponent,
    LayoutHeaderComponent,
    ColumnDateComponent,
    AutofocusDirective,
    TrimDirective,
    TextBoxModule,
    LabelModule,
    InputsModule,
  ],
  providers: [
  ]
})
export class SharedModule { }
