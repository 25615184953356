import { Component, OnInit, HostListener, Directive } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms';

import { of, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AuthService } from '../../../../core/services/auth.service';
import { AlertService } from '../../../../core/services/alert.service';
import { ButtonState } from '../../../../shared/models/ui.model';

import * as Common from '../../../../shared/models/common.model';
import * as Enums from '../../../../shared/models/gen.enums';
import * as User from '../../../../shared/models/gen.user.model';
import { UserService } from '../../../../core/services/user.service';


@Directive()
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.page.html'
})
export class UserEditPage implements OnInit, Common.CanComponentDeactivate {

  thisThingLCS = "user";

  saveBtn: ButtonState = {
    iconClass: "fad fa-save" 
  };

  isReadOnly = false;

  model: UntypedFormGroup;
  view: User.View;
  detail: User.Detail;
  saveResult: User.SaveResult;
  
  newCategory = "";
  newTag = "";

  loaded = false;

  routeInfo: {
    id: string;
  };

  isNew: boolean;
  
  validationMsg = UserService.validationMsg;

  saveMsg = {
    invalid: "Please fix the errors on this page before saving",
    success: "The test was saved successfully",
    error: "An error occurred while saving the test"
  };

  constructor(private fb: UntypedFormBuilder,
    private authSvc: AuthService,
    private userSvc: UserService,
    private alertSvc: AlertService,
    private route: ActivatedRoute,
    private router: Router)
  { }

  ngOnInit() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of({
        id: params.get('id')
      }))
    )
      .pipe(take(1))
      .subscribe(info => {
      this.routeInfo = info;

      const filter: User.Filter = {
        id: info.id
      };
        this.userSvc.getUser(filter)
          .pipe(take(1))
          .subscribe(result => {
        this.view = result;
        this.detail = result.detail;

        this.isNew = this.detail.id === null;

        this.model = this.userSvc.getUserModel(this.fb);
        this.model.patchValue(this.detail);

        // If this is a copy, mark the form as dirty so the Save button is enabled.
        if (history.state.copyDetail)
          this.model.markAsDirty();

        this.loaded = true;
        
      }, error => {
          this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
        console.error(error);
      });

    }, error => {
      this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
      console.error(error);
    });

  }
  //

  get f() { return this.model.controls; }

//

  save() {
    this.model.markAllAsTouched();

    if (!this.model.valid) {
      this.alertSvc.showSubmitInvalid(this.saveBtn, this.saveMsg.invalid);
      return;
    }

    this.alertSvc.showSubmitStart(this.saveBtn);

    this.userSvc.saveUser(this.model.value)
      .pipe(take(1))
      .subscribe(result => {
      if (!this.alertSvc.processModelErrors(result.modelErrors, this.model, this.saveBtn, this.saveMsg.invalid)) {
        this.model.markAsPristine();
        this.router.navigateByUrl("/users");
      }
    }, error => {
      this.alertSvc.showSubmitError(this.saveBtn, this.saveMsg.error);
      console.error(error);
    });
  }

  //copy() {
  //  const extras = {
  //    state: {
  //      copyDetail: this.model.value
  //    }
  //  };
  //  this.router.navigateByUrl("/users/new", extras);
  //}

  cancel() {
    this.router.navigateByUrl("/users");
  }

  //

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.model.dirty)
      return true;
    return this.alertSvc.confirm({
      title: "Leave?",
      content: "Do you want to leave without saving your changes?"
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(e) {
    if (this.model.dirty)
      e.returnValue = "Are you sure you want to leave?";
  }
}
