import { Component, ElementRef, ViewChild, HostListener, Input } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styles: []
})
export class PopupComponent {
  @ViewChild('anchor') anchor: ElementRef;
  @ViewChild('popup') popup: ElementRef;

  @Input() anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  @Input() popupAlign: Align = { horizontal: "right", vertical: "top" };

  showPopup = false;

  constructor() { }

  private contains(target: any, anchor: ElementRef, popup: ElementRef): boolean {
    return (anchor && anchor.nativeElement.contains(target)) ||
      (popup && popup.nativeElement ? popup.nativeElement.contains(target) : false);
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event): void {
    if (!this.contains(event.target, this.anchor, this.popup))
      this.togglePopup(false);
  }

  togglePopup(show?: boolean) {
    this.showPopup = show !== undefined ? show : !this.showPopup;
  }
}
