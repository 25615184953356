import { Component, Input, forwardRef, ViewChild, TemplateRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';
import { AlertService } from '../../../core/services/alert.service';


@Component({
  // tslint:disable-next-line:no-forward-ref
  providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolButtonComponent) }],
  selector: 'app-tool-button',
  templateUrl: './tool-button.component.html',
  styles: []
})
export class ToolButtonComponent extends ToolBarToolComponent {
  tabIndex = -1;

  @Input() text = "";
  @Input() className = "";
  @Input() iconClass = "";
  @Input() disabled = false;
  @Input() disableWhenPristine = false;
  @Input() model: UntypedFormGroup;

  @ViewChild('toolbarTemplate', { static: true }) public toolbarTemplate: TemplateRef<any>;
  @ViewChild('button') public button: ElementRef;

  @Output() click = new EventEmitter();

  constructor(private alertSvc: AlertService) {
    super();
  }

  canFocus(): boolean {
    return !this.disabled;
  }

  focus(ev): void {
    // guard against focusing twice on mousedown.
    if (!ev || ev.type === 'focus' || ev.type === 'keydown') {
      // focus the focusable element
      this.button.nativeElement.focus();
    }

    // manage the roving tabindex: see https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex
    this.tabIndex = 0;
  }

  public handleKey(): boolean {
    // manage the roving tabindex: see https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex
    this.tabIndex = -1;
    // tell the toolbar that the focus can move to the next/prev focusable tool
    return false;
  }


  isDisabled(): boolean {
    return this.disabled || (this.disableWhenPristine && this.model?.pristine);
  }

  getClass() {
    const state = this.alertSvc.getButtonStateClass(this.model);
    return !this.className ? state : [state, this.className].join(" ");
  }
}
