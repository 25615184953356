import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AlertService } from '../../../../core/services/alert.service';
import { ButtonState } from '../../../../shared/models/ui.model';
import * as Common from '../../../../shared/models/common.model';
import * as Grid from '../../../../shared/models/grid.model';
import * as Tag from '../../../../shared/models/gen.tag.model';
import { TagService } from '../../../../core/services/tag.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.page.html'
})
export class TagListPage {
  thisThingLCS = "tag";
  thisThingLCP = "tags";
  thisThingUCP = "Tags";

  loaded = false;
  activeBtn: ButtonState;
  grid: Grid.GridInfo<Tag.ListItem>;
  selectedRows: Tag.Detail[] = [];

  deleteBtn: ButtonState = {
    iconClass: "fad fa-trash"
  };

  constructor(
    http: HttpClient, 
    private alertSvc: AlertService,
    private tagSvc: TagService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject('BASE_URL') baseUrl: string)
  {

    http.get<Tag.List>(baseUrl + 'api/Tag/GetList')
      .pipe(take(1))
      .subscribe(result => {
      this.grid = new Grid.GridInfo(result.details, {
        sort: [
          { field: 'name', dir: 'asc' }
        ]
      });

      this.activeBtn = this.grid.activeBtn;
    this.loaded = true;

      }, error => {
        this.alertSvc.showError("An error occurred while loading the list of {thisThingLCP}");
        console.error(error);
      });
  }

  //

  getLinkUrl(dataItem) {
    return "/tags/" + (dataItem ? dataItem.id : "new");
  }

  newItem() {
    this.router.navigateByUrl(this.getLinkUrl(null));
  }

  openItem() {
    if (this.grid.selectedKeys.length === 1)
      this.router.navigateByUrl(this.getLinkUrl(this.grid.selectedKeys[0]));
  }

  //

  getDeletionDetail(): Tag.DeletionDetail {
    const detail: Tag.DeletionDetail = {
      ids: []
    };
    this.grid.selectedKeys.forEach((item) => {
      detail.ids.push(item.id);
    });
    return detail;
  }

  delete() {
    if (this.grid.selectedKeys.length > 0) {
      const detail = this.getDeletionDetail();

      this.alertSvc.confirmDelete({
        detail: detail,
        singularLower: "tag",
        singularUpper: "Tag",
        pluralLower: "tags",
        pluralUpper: "Tags",
        action: (successMessage: string, errorMessage: string) => {
          this.alertSvc.showSubmitStart(this.deleteBtn);
          this.tagSvc.deleteTags(detail)
            .pipe(take(1))
            .subscribe(result => {

              if (result.inUse.length > 0) {
                if (result.inUse.length == 1) {
                  var msg = "The tag '" + result.inUse[0].name + "' cannot be deleted because it is in use by a report"
                  this.alertSvc.showError(msg);
                }
                else {
                  var msg = "The tags '" +
                    Array.prototype.map.call(result.inUse, s => s.name).join("' , '")
                    + "' cannot be deleted because they are in use by a report";
                  this.alertSvc.showError(msg);
                }
              }

              if (result.error.length > 0) {
                if (result.error.length == 1) {
                  var msg = "An error occurred deleting tag '" + result.error[0].name + "'"
                  this.alertSvc.showError(msg);
                }
                else {
                  var msg = "An error occurred deleting tags '" +
                    Array.prototype.map.call(result.error, s => s.name).join("' , '")
                    + "'";
                  this.alertSvc.showError(msg);
                }
              }

              if (result.deleted.length > 0) {
                if (result.deleted.length == 1) {
                  var msg = "The tag '" + result.deleted[0].name + "' was deleted"
                  this.alertSvc.showSuccess(msg);
                }
                else {
                  var msg = "The tags '" +
                    Array.prototype.map.call(result.deleted, s => s.name).join("' , '")
                    + "' were deleted";
                  this.alertSvc.showSuccess(msg);
                }
                this.grid.removeItems(result.deleted);
              }

          }, error => {
            this.alertSvc.showSubmitError(this.deleteBtn, errorMessage);
            console.error(error);
          });
        }
      });
    }
  }


}

