import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Common from '../../shared/models/common.model';
import * as Text from '../../shared/models/gen.text.model';
import * as Auth from '../../shared/models/auth.model';
import * as Configuration from '../../shared/models/gen.configuration.model';
import * as Validation from '../../shared/models/gen.validation';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  public baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getText(filter: Text.Filter): Observable<Text.View> {
    return this.http.post<Text.View>(
      this.baseUrl + 'api/Text/Get', filter);
  }

  getTextByType(filter: Text.GetTextTypeFilter): Observable<Text.GetTextResult> {
    return this.http.post<Text.GetTextResult>(
      this.baseUrl + 'api/Text/GetByType', filter);
  }

  saveText(detail: Text.Detail): Observable<Text.SaveResult> {
    return this.http.post<Text.SaveResult>(
      this.baseUrl + 'api/Text/Save', detail);
  }

  toggleActive(detail: Common.IdActive): Observable<Text.ActiveResult> {
    return this.http.post<Text.ActiveResult>( 
      this.baseUrl + 'api/Text/ToggleActive', detail);
  }

  getTextModel(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({

      id: [''],

      type: ['', [
        Validators.required,
      ]],

      typeAsString: [''],

      text: ['', [
        Validators.required,
      ]],

      isActive: ['', [
        Validators.required,
      ]],

    });
  }

  static validationMsg = {
    type: {
      required: Validation.TextType.requiredMessage,
    },
    text: {
      required: Validation.TextText.requiredMessage,
    },
    isActive: {
      required: Validation.TextIsActive.requiredMessage,
    }
  }; 

}
