import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module'; 
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';

import { TextListPage } from './pages/text-list/text-list.page';
import { TextEditPage } from './pages/text-edit/text-edit.page';

const MODULE_ROUTES: Routes = [
  {
    path: 'text/:id',
    component: TextEditPage,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }, {
    path: 'text',
    component: TextListPage,
    canActivate: [AuthGuard]
  }
];

@NgModule({
	declarations: [
    TextListPage,
    TextEditPage
	],
	imports: [
		CommonModule,
		RouterModule.forChild(MODULE_ROUTES),
		SharedModule
	],
  exports: [
    TextListPage,
    TextEditPage
	]
})
export class TextModule { }
