import { Component, enableProdMode, OnDestroy, OnInit, Directive } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';

@Directive()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = "UMD";
  subscription = new Subscription();
  routerSub = new Subscription();
  showPrivate = false;

  constructor(private authSvc: AuthService,
    private router: Router,
  ) {
    this.authSvc.initJsonDateParser();
    //this.authSvc.checkApplicationStatus();
  }

  ngOnInit() {
    this.subscription.add(this.authSvc.sessionSubject.subscribe((value) => {
      this.showPrivate = value.OnPrivateReportPage;
    }));

    this.routerSub = this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          var session = this.authSvc.getSession();

          session.OnPublicListPage = false;
          session.OnPrivateListPage = false;
          session.OnPublicReportPage = false;
          session.OnPrivateReportPage = false;
          session.OnAdminPage = false;

          if (this.router.url == "/") {
            session.OnPublicListPage = true;
          }

          if (this.router.url == "/community") {
            session.OnPrivateListPage = true;
          }

          if (this.router.url.startsWith("/tableaupublic")) {
            session.OnPublicReportPage = true;
          }

          if (this.router.url.startsWith("/community/tableauprivate")
            || this.router.url.startsWith("/community/sasprivate")) {
            session.OnPrivateReportPage = true;
          }

          // If none of these, must be on an admin page

          if (!session.OnPublicListPage
            && !session.OnPrivateListPage
            && !session.OnPublicReportPage
            && !session.OnPrivateReportPage) {
            session.OnAdminPage = true;
          }

          this.authSvc.updateSession(session);
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
    this.routerSub && this.routerSub.unsubscribe();
  }
}
