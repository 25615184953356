import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrim]'
})
export class TrimDirective {

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private formControl: NgControl) { }

  @HostListener('keyup.enter') onEnter() {
    this.trim();
  }

  @HostListener('focusout') onFocusout() {
    this.trim();
  }

  @HostListener('blur') onBlur() {
    this.trim();
  }

  private trim() {
    if (this.formControl.value)
      this.formControl.control.setValue(this.formControl.value.trim());
    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.trim();
  }

}


