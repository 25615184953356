import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';

import { UserListPage } from './pages/user-list/user-list.page';
import { UserEditPage } from './pages/user-edit/user-edit.page';

const MODULE_ROUTES: Routes = [
  {
    path: 'users/:id',
    component: UserEditPage,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }, {
    path: 'users',
    component: UserListPage,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  declarations: [
    UserListPage,
    UserEditPage
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(MODULE_ROUTES),
    SharedModule
  ],
  exports: [
    UserListPage,
    UserEditPage
  ]
})
export class UsersModule { }
