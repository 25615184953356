import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModelError } from '../../shared/models/common.model';
import { ButtonState } from '../../shared/models/ui.model';
import { Observable } from 'rxjs';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { map } from 'rxjs/operators';
import * as Common from '../../shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private notificationSvc: NotificationService,
    private dialogSvc: DialogService) {
  }

  static revertDelay = 3000;
  static saveIcons = {
    original: "fa-save",
    invalid: "fa-exclamation",
    wait: "fa-hourglass-half",
    success: "fa-check-circle",
    error: "fa-times",
  }


  public showError(message: string) {
    this.notificationSvc.show({
      content: message,
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: "error", icon: true }
    });
  }

  public showSuccess(message: string) {
    this.notificationSvc.show({
      content: message,
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: "success", icon: true }
    });
  }

  public showInfo(message: string) {
    this.notificationSvc.show({
      content: message,
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: "info", icon: true }
    });
  }

  public showWarning(message: string) {
    this.notificationSvc.show({
      content: message,
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: "warning", icon: true }
    });
  }



  private saveRevertIcon(btnState: ButtonState, revertClass: string) {
    btnState.originalIconClass = revertClass;
  }

  private switchIcon(btnState: ButtonState, newClass: string) {
    if (!btnState.originalIconClass)
      this.saveRevertIcon(btnState, btnState.iconClass);
    btnState.iconClass = "fad " + newClass;
  }

  private revertIcon(btnState: ButtonState) {
    btnState.iconClass = btnState.originalIconClass;
    btnState.originalIconClass = null;
  }

  private switchIconDelay(btnState: ButtonState, newClass: string, onReverted?: () => void) {
    this.cancelRevert(btnState);

    this.switchIcon(btnState, newClass);

    btnState.timeoutId = setTimeout(() => {
      this.revertIcon(btnState);
      if (onReverted)
        onReverted();
    }, AlertService.revertDelay) as unknown as number;
  }

  public cancelRevert(btnState: ButtonState): boolean {
    const timeoutId = btnState.timeoutId;
    if (!timeoutId)
      return false;

    clearTimeout(timeoutId);
    btnState.timeoutId = null;
    return true;
  }



  private createControlMap(model: UntypedFormGroup) {
    const controlMap = {};
    const addControl = (prefix, controls) => {
      $.each(Object.keys(controls), (i: number, o: string) => {
        const control = controls[o];
        if (control.controls)
          addControl(prefix + o.toLowerCase() + ".", control.controls); // FormGroup
        else
          controlMap[prefix + o.toLowerCase()] = control; // FormControl
      });
    };
    addControl("", model.controls);
    return controlMap;
  }



  public processModelErrors(modelErrors: ModelError[], model: UntypedFormGroup,
    btnState: ButtonState, message?: string): boolean {
    if (!modelErrors || modelErrors.length === 0)
      return false;

    const controlMap = this.createControlMap(model);

    $.each(modelErrors, (i: number, o: ModelError) => {
      if (!o.propertyName) {
        message = o.description;
      } else {
        const control = controlMap[o.propertyName.toLowerCase()] as UntypedFormControl;
        if (control)
          control.setErrors({ serverError: o.description });
      }
    });

    this.showSubmitInvalid(btnState, message);
    return true;
  }



  public processAjaxError(error, model: UntypedFormGroup, btnState: ButtonState,
    invalidMsg: string, errorMsg: string) {
    if (error.status === 400 && error.error.errors) {
      const controlMap = this.createControlMap(model);

      $.each(error.error.errors, (key: string, values: string[]) => {
        const control = controlMap[key.toLowerCase()] as UntypedFormControl;
        if (control && values.length > 0)
          control.setErrors({ serverError: values[0] });
      });
      
      this.showSubmitInvalid(btnState, invalidMsg);
    } else {
      this.showSubmitError(btnState, errorMsg);
      console.error(error);
    }
  }



  public showSubmitInvalid(btnState: ButtonState, message?: string) {
    this.switchIconDelay(btnState, AlertService.saveIcons.invalid);
    btnState.disabled = false;
    if (message)
      this.showWarning(message);
  }

  public showSubmitStart(btnState: ButtonState) {
    this.switchIcon(btnState, AlertService.saveIcons.wait);
    btnState.disabled = true;
  }

  public showSubmitSuccess(btnState: ButtonState, message?: string, onReverted?: () => void) {
    this.switchIconDelay(btnState, AlertService.saveIcons.success, onReverted);
    btnState.disabled = false;
    if (message)
      this.showSuccess(message);
  }

  public showSubmitError(btnState: ButtonState, message?: string) {
    this.switchIconDelay(btnState, AlertService.saveIcons.error);
    btnState.disabled = false;
    if (message)
      this.showError(message);
  }

  public showSubmitComplete(btnState: ButtonState) {
    this.revertIcon(btnState);
    btnState.disabled = false;
  }



  public getButtonStateClass(model: UntypedFormGroup, allowPristine = true) {
    return (allowPristine && model?.pristine) ? 'btn-pristine' : model?.invalid ? 'btn-invalid' : 'btn-changed';
  }



  public confirm(options: Common.ConfirmOptions): Observable<boolean> {
    const dialog: DialogRef = this.dialogSvc.open({
      title: options.title || "Please confirm",
      content: options.content || "Are you sure?",
      actions: [
        { text: options.yesText || "Yes", primary: true, value: true },
        { text: options.noText || "No", value: false }
      ],
      //width: options.width || 450,
      minWidth: options.minWidth || 250,
      actionsLayout: "normal"
    });

    return dialog.result
      .pipe(map((result): boolean => {
        if (result instanceof DialogCloseResult)
          return false;
        return (result as Common.ConfirmAction).value;
      }));
  }

  public confirmDelete(options: Common.ConfirmDeleteOptions) {
    let confirmMessage: string;
    let title: string;
    let successMessage: string;
    let errorMessage: string;

    const itemCount = options.detail.ids.length;
    title = "Delete Items";
    if (itemCount == 1) {
      confirmMessage = "Are you sure you want to delete this " + options.singularLower + "?";
      successMessage = "The " + options.singularLower + " was deleted successfully";
      errorMessage = "An error occurred while deleting the " + options.singularLower;
    }
    else {
      confirmMessage = "Are you sure you want to delete these " + itemCount + " " + options.pluralLower + "?";
      successMessage = "The " + itemCount + " " + options.pluralLower + " were deleted successfully";
      errorMessage = "An error occurred while deleting the " + options.pluralLower;
    }

    const confirmOptions: Common.ConfirmOptions = $.extend({}, {
      title: title,
      content: confirmMessage,
      yesText: "Delete",
      noText: "Cancel"
    }, options.confirmOptions);
    this.confirm(confirmOptions).subscribe(result => {
      if (result)
        options.action(successMessage, errorMessage);
    });
  }

  public showErrorInUse(btnState: ButtonState, modelErrors: ModelError[]) {
    this.switchIconDelay(btnState, AlertService.saveIcons.invalid);
    btnState.disabled = false;
    for (var msg in modelErrors) {
      this.showSubmitError(btnState, modelErrors[msg].description);
    }
  }

  public confirmToggleActive(options: Common.ConfirmToggleActiveOptions) {
    if (options.detail.active) {
      options.action(options.detail); // Don't confirm if we're changing from inactive to active
    } else {
      const objectType = options.objectType || "Item";
      const confirmOptions: Common.ConfirmOptions = $.extend({}, {
        title: "Delete " + objectType,
        content: "Are you sure you want to delete the selected " + objectType + "?",
        yesText: "Delete",
        noText: "Cancel"
      }, options.confirmOptions);
      this.confirm(confirmOptions).subscribe(result => {
        if (result)
          options.action(options.detail);
      });
    }
  }

  public confirmToggleDeleted(options: Common.ConfirmToggleDeletedOptions) {
    if (!options.detail.deleted) {
      options.action(options.detail); // Don't confirm if we're changing from indeleted to deleted
    } else {
      const objectType = options.objectType || "Item";
      const confirmOptions: Common.ConfirmOptions = $.extend({}, {
        title: "Delete " + objectType,
        content: "Are you sure you want to delete the selected " + objectType + "?",
        yesText: "Delete",
        noText: "Cancel"
      }, options.confirmOptions);
      this.confirm(confirmOptions).subscribe(result => {
        if (result)
          options.action(options.detail);
      });
    }
  }


}
