import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';

import { ReportListPage } from './pages/report-list/report-list.page';
import { ReportEditPage } from './pages/report-edit/report-edit.page';

const MODULE_ROUTES: Routes = [
  {
    path: 'reports/:id',
    component: ReportEditPage,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }, {
    path: 'reports',
    component: ReportListPage,
    canActivate: [AuthGuard]
  }
];

@NgModule({
	declarations: [
    ReportListPage,
    ReportEditPage
	],
	imports: [
    CommonModule,
		RouterModule.forChild(MODULE_ROUTES),
    SharedModule
	],
  exports: [
    ReportListPage,
    ReportEditPage
	]
})
export class ReportsModule { }
