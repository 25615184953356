import { Component, OnInit, OnDestroy, Inject, Directive } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import * as Auth from '../../../shared/models/auth.model';
import { AuthService } from '../../../core/services/auth.service';
import { SettingsService } from '../../../core/services/settings.service';
import * as Enums from '../../../shared/models/gen.enums';
import { take } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';


@Directive()
@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styles: []
})
export class LayoutHeaderComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  isLoggedIn: boolean;
  userId: number;
  userName: string;
  displayName: string;

  adminItems: any[];
  defaultAdminItem: any;
  adminValue: any;

  public onPublicReportPage: boolean = null;
  public onPrivateReportPage: boolean = null;
  public onPublicListPage: boolean = null;
  public onPrivateListPage: boolean = null;
  public onAdminPage: boolean = null;

  public isAdmin = false;


  constructor(@Inject(DOCUMENT) private document: Document,
    private authSvc: AuthService,
    private settingsSvc: SettingsService,
    private router: Router)
  {
  }


  ngOnInit() {
    this.subscription.add(this.authSvc.sessionSubject.subscribe(value => {
      this.isLoggedIn = value.IsAuthenticated;
      this.userName = value.UserName;
      this.displayName = value.DisplayName;
      this.isAdmin = value.IsAdmin;

      this.onPublicListPage = value.OnPublicListPage;
      this.onPrivateListPage = value.OnPrivateListPage;
      this.onPublicReportPage = value.OnPublicReportPage;
      this.onPrivateReportPage = value.OnPrivateReportPage;
      this.onAdminPage = value.OnAdminPage;

      this.adminItems = null;

      if (this.isLoggedIn && this.isAdmin) {

        this.defaultAdminItem = {
          value: null,
          text: 'Admin Functions',
          path: null,
        };

        this.adminItems = [
          {
            value: 10,
            text: 'Categories',
            path: "/categories",
          },
          {
            value: 20,
            text: 'General Text',
            path: "/text",
          },
          {
            value: 30,
            text: 'Reports',
            path: "/reports",
          },
          {
            value: 40,
            text: 'Tags',
            path: "/tags",
          },
          {
            value: 50,
            text: 'Users',
            path: "/users",
          },
        ];
      }
    }));
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  login() {
    this.document.location.href = "/auth/login";
  }


  reportList() {
    if (this.onPrivateReportPage) {
      this.router.navigateByUrl("/community");
    }
    else {
      this.router.navigateByUrl("/");
    }
  }


  navigatePublic() {
    this.router.navigateByUrl("/");
  }


  navigatePrivate() {
    this.router.navigateByUrl("/community");
  }


  adminNavigate(event: any) {
    if (this.isAdmin && event.path) {
      var target = event.path;
      this.adminValue = null;
      this.router.navigateByUrl(target);
    }
    this.adminValue = null;
  }

}
