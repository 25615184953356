import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-column-date',
  templateUrl: './column-date.component.html',
  styles: []
})
export class ColumnDateComponent {
  @Input() url: string;
  @Input() title: string;
  @Input() date: Date;
}
