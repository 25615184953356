import { Component, Input, Inject, SecurityContext, Directive } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Home from '../../../../shared/models/gen.home.model';
import * as Common from '../../../../shared/models/common.model';
import { HomeService } from '../../../../core/services/home.service';
import { AuthService } from '../../../../core/services/auth.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as Enums from '../../../../shared/models/gen.enums';
import { AlertService } from '../../../../core/services/alert.service';
import { take } from 'rxjs/operators';

@Directive()
@Component({
  selector: 'app-community',
  templateUrl: './community.page.html'
})
export class CommunityPage {
  view: Home.View;
  viewAll: Home.View;
  tosToggle: boolean = false;
  isLoaded = false;

  work: Home.Detail[];
  selectedTag: number = null;
  filterText: string = null;
  sortOptions: { id: number, name: string }[];
  selectedSort: number = 0;
  filterFavorites: boolean;

  isAuthenticated: boolean = false;

  thumbnailDict: Map<number, string> = new Map<number, string>();

  _baseUrl: string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private homeSvc: HomeService,
    private authSvc: AuthService,
    private alertSvc: AlertService,
    private router: Router,
    private sanitizer: DomSanitizer) {

    this._baseUrl = baseUrl;

    this.sortOptions = [
      { id: 0, name: "Title" },
      { id: 1, name: "Most Popular" },
      { id: 2, name: "Recently Updated" }
    ];
  }

  ngOnInit() {
    this.tosToggle = !this.authSvc.getSession().HasConfirmedTos;

    // Set flag for header
    //var session = this.authSvc.getSession();
    //session.OnPrivatePage = true;
    //session.OnLandingPage = true;
    //this.authSvc.updateSession(session);

    if (this.authSvc.isExpired(this.authSvc.getSession())) {
      this.authSvc.redirectToLogin("/community");
    }
    else {
      this.init();
    }
  }

  init() {
    if (!this.authSvc.getSession().IsPrivateLoaded) {
      this.homeSvc.updateUserReports()
        .pipe(take(1))
        .subscribe(result => {

        // If any of them loaded, continue
        if (result.sasResult || result.tableauResult || result.tableauStudentResult) {
          var session = this.authSvc.getSession();

          // But show alerts for any that didn't load
          if (!result.sasResult) {
            this.alertSvc.showError("Problem loading SAS reports")
          }
          if (!result.tableauResult) {
            this.alertSvc.showError("Problem loading Tableau default site reports")
          }
          if (!result.tableauStudentResult) {
            this.alertSvc.showError("Problem loading Tableau student site reports")
          }

          session.IsPrivateLoaded = true;
          this.authSvc.updateSession(session);
          this.loadAll();
        }
        else {
          this.alertSvc.showError("Error loading user reports")
        }
      }, error => {
        this.alertSvc.showError("Error loading user reports")
      });
    }
    else {
      this.loadAll();
    }
  }

  loadAll() {

    this.selectedTag = null;

    if (this.authSvc.isExpired(this.authSvc.getSession())) {
      this.authSvc.redirectToLogin("/community");
    }

    this.http.get<Home.View>(this._baseUrl + 'api/Home/GetPrivateView')
      .pipe(take(1))
      .subscribe(result => {

      this.viewAll = result;

      // Get virtual URLs for images that are coming from the DB

      let url: string = "";
      for (let i of this.viewAll.details) {

        if (i.reportTypeId == Enums.ReportType.SASPrivate) {
          url = '/images/SAS_Thumbnail.png';
          i.tempThumbnailUrl = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(url));
          this.thumbnailDict.set(i.id, i.tempThumbnailUrl);
        }

        else if (i.thumbnail != null) {
          url = 'data:image/png;base64,' + i.thumbnail;
          i.tempThumbnailUrl = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustUrl(url));
          this.thumbnailDict.set(i.id, i.tempThumbnailUrl);
        }

      };

      // Continue loading and filtering

      this.cachedLoad();

    }, error => console.error(error));
  }

  sortNumbersDesc(a: number, b: number) {
    if (a === b) {
      return 0;
    }

    // nulls sort after anything else
    else if (a === null) {
      return 1;
    }
    else if (b === null) {
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    else {
      return a < b ? 1 : -1;
    }
  }

  cachedLoad()
  {
    this.isAuthenticated = this.authSvc.getSession().IsAuthenticated;

    // Filter by selected tag

    this.view = {
      details: null,
      tags: this.viewAll.tags,
    }

    this.work = this.viewAll.details;

    if (this.filterFavorites) {
      this.work = this.work.filter(o => o.isFavorite);
    }

    if (this.filterText) {
      this.work = this.work.filter(o => o.searchString.includes(this.filterText.toUpperCase()));
    }

    if (this.selectedTag) {
      this.work = this.work.filter(o => o.tags.map(a => a.id).includes(this.selectedTag));
    }

    // Sort by Popularity
    if (this.selectedSort == 1) {
      this.work.sort((one, two) => (one.totalReportHits > two.totalReportHits) ? -1 : 1);
    }
    // Sort by Last Updated
    else if (this.selectedSort == 2) {
      this.work.sort((one, two) => (one.updatedAt > two.updatedAt) ? -1 : 1);
    }
    // Default: Sort by Title
    else {
      this.work.sort((one, two) => (one.title.toLocaleUpperCase() < two.title.toLocaleUpperCase() ? -1 : 1));
    }

    this.view.details = this.work;

    this.isLoaded = true;
  }

  // Search, Filters, Favorites

  setSelectedTag(value: any) {
    this.selectedTag = value.id;
    this.cachedLoad();
  }

  setFilterText() {
    //this.filterText = value.value.toUpperCase();
    this.cachedLoad();
  }

  setSelectedSort(value: any) {
    this.selectedSort = value.id;
    this.cachedLoad();
  }

  clearFilter() {
    this.selectedTag = null;
    this.filterText = null;
    this.filterFavorites = false;
    this.cachedLoad();
  }

  toggleFavorite(id: number, event: any) {
    event.preventDefault();
    var reportIdClass: Common.IdClass = { id: id };
    this.homeSvc.toggleFavorite(reportIdClass)
      .pipe(take(1))
      .subscribe(result => {
      this.view.details.filter(o => o.id == id).forEach(o => o.isFavorite = result);
      // Update report with new favorite status
    }, error => {
      console.error(error);
    });
  }

  filterFavoritesOff(event: any) {
    event.preventDefault();
    this.filterFavorites = false;
    this.cachedLoad();
  }

  filterFavoritesOn(event: any) {
    event.preventDefault();
    this.filterFavorites = true;
    this.cachedLoad();
  }

  //

  getQueryStringValue(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  //

  reportClick(id: number, event) {

    event.preventDefault();

    var thisItem = this.view.details.findIndex(o => o.id == id);

    var reportIdClass: Common.IdClass = { id: id };
    this.homeSvc.logReportHit(reportIdClass)
      .pipe(take(1))
      .subscribe(result => {
    }, error => {
      console.error(error);
    });

    // Lookup type of report
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.TableauPrivate) {
      window.open("/community/tableauprivate/" + id, "_blank");
    }
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.TableauPrivateStudent) {
      window.open("/community/tableauprivatestudent/" + id, "_blank");
    }
    if (this.view.details[thisItem].reportTypeId == Enums.ReportType.SASPrivate) {
      window.open("/community/sasprivate/" + id, "_blank");
    }
    //if (this.view.details[thisItem].reportTypeId == Enums.ReportType.PDF) {
    //  this.router.navigateByUrl("/pdf/" + id);
    //}
  }

  //

  tosCancel() {
    this.tosToggle = false;
    this.router.navigateByUrl("/");
  }

  tosConfirm() {
    this.tosToggle = false;
    this.homeSvc.saveConfirmTos()
      .pipe(take(1))
      .subscribe(result => {
      var session = this.authSvc.getSession();
      session.HasConfirmedTos = true;
      this.authSvc.updateSession(session);
    }, error => {
      console.error(error);
    });
  }

  toggleExpand(event) {
    $(event.target).closest(".card").toggleClass("card-private-expand");
  }
}
