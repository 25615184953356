import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Tag from '../../shared/models/gen.tag.model';
import * as Validation from '../../shared/models/gen.validation';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  public baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  // Get

  getTag(filter: Tag.Filter): Observable<Tag.View> {
    return this.http.post<Tag.View>(
      this.baseUrl + 'api/Tag/Get', filter);
  }

  // Save

  saveTag(detail: Tag.Detail): Observable<Tag.SaveResult> {
    return this.http.post<Tag.SaveResult>(
      this.baseUrl + 'api/Tag/Save', detail);
  }

  // Delete

  deleteTags(detail: Tag.DeletionDetail): Observable<Tag.DeletionResult> {
    return this.http.post<Tag.DeletionResult>(
      this.baseUrl + 'api/Tag/Delete', detail);
  }

    // Form

  getTagModel(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({

      id: [''],

      name: ['', [
        Validators.required,
        Validators.maxLength(Validation.TagName.maxLength)
      ]]

    });
  }

  // Validation

  static validationMsg = { 
    name: {
      required: Validation.TagName.requiredMessage,
      maxlength: Validation.TagName.maxLengthMessage
    }
  }; 

}
