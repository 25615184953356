import { Injectable } from '@angular/core';
import { FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Injectable({
	providedIn: 'root'
})
export class GridService {
	constructor() { }

	private flattenFilters(filter) {
		const filters = (filter || {}).filters;
		if (filters) {
			return filters.reduce((acc, curr) => acc.concat(curr.filters ? this.flattenFilters(curr) : [curr]), []);
		}
		return [];
	}

  public applyFilter(values: any[], filterService: FilterService, field: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: "eq",
        value
      })),
      logic: "or"
    });
  }

  public applyFilterContains(values: any[], filterService: FilterService, field: string): void {
    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: "contains",
        value
      })),
      logic: "or"
    });
  }

	public updateFilter(filter: CompositeFilterDescriptor, fieldFilter: any): FilterDescriptor[] {
		fieldFilter.splice(0, fieldFilter.length,
			...this.flattenFilters(filter).map(({ value }) => value)
		);
		return fieldFilter;
	}
}
