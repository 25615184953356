import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-multi-check-list',
  templateUrl: './multi-check-list.component.html',
  styles: []
})
export class MultiCheckListComponent {
  @Input() data: any[] = [];
  @Input() textField;
  @Input() valueField;
  @Input() value: any[];

  @Output() valueChange = new EventEmitter<any[]>();


  change(event) {
    const $li = $(event.target);
    const dataIndex = $li.index();
    if (dataIndex < 0 || dataIndex >= this.data.length)
      return;

    const value = this.getValue(this.data[dataIndex]);
    const index = $.inArray(value, this.value);
    if (index < 0)
      this.value.push(value);
    else
      this.value.splice(index, 1);
    this.valueChange.emit(this.value);
  }

  getText(item: any) {
    return this.textField ? item[this.textField] : item;
  }

  getValue(item: any) {
    return this.valueField ? item[this.valueField] : item;
  }

  getSelected(item: any): boolean {
    return $.inArray(this.getValue(item), this.value) >= 0;
  }
}
