import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules, UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import * as $ from 'jquery';

import { SessionInterceptor } from './shared/interceptors/session.interceptor';
import { LowerCaseUrlSerializer } from './route-serializer';

import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AppComponent } from './app.component';
import { LayoutPublicComponent } from './core/components/layout-public/layout-public.component';
import { LayoutPrivateComponent } from './core/components/layout-private/layout-private.component';

import { APP_ROUTES } from './app.routes';
import { AuthGuard } from './shared/guards/auth.guard';

import { SharedModule } from './shared/shared.module';

import { CategoriesModule } from './modules/categories/categories.module';
import { HomeModule } from './modules/home/home.module';
import { ReportsModule } from './modules/reports/reports.module';
import { TagsModule } from './modules/tags/tags.module';
import { TextModule } from './modules/text/text.module';
import { UsersModule } from './modules/users/users.module';
//import { NgxTextOverflowClampModule } from "ngx-text-overflow-clamp";

@NgModule({
  declarations: [
    AppComponent,
    LayoutPublicComponent,
    LayoutPrivateComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    HomeModule,
    ReportsModule,
    TagsModule,
    TextModule,
    CategoriesModule,
    UsersModule,
    TooltipModule,
    //NgxTextOverflowClampModule,
    RouterModule.forRoot(APP_ROUTES,
      {
        preloadingStrategy: PreloadAllModules
      }
    )
  ],
  providers: [AuthGuard, 
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true }
  ],
bootstrap: [AppComponent]
})
export class AppModule { }
