import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';

import { HomePage } from './pages/home/home.page';
import { CommunityPage } from './pages/home/community.page';
import { AdminPage } from './pages/admin/admin.page';

import { TableauPublicPage } from './pages/home/tableaupublic.page';
import { TableauPrivatePage } from './pages/home/tableauprivate.page';
import { TableauPrivateStudentPage } from './pages/home/tableauprivatestudent.page';
import { SasPrivatePage } from './pages/home/sasprivate.page';
//import { NgxTextOverflowClampModule } from "ngx-text-overflow-clamp";


const MODULE_ROUTES: Routes = [
  {
    path: '', component: HomePage
  },
  {
    path: 'community/tableauprivate/:id',
    component: TableauPrivatePage
  },
  {
    path: 'community/tableauprivatestudent/:id',
    component: TableauPrivateStudentPage
  },
  {
    path: 'community/sasprivate/:id',
    component: SasPrivatePage
  },
  {
    path: 'community', component: CommunityPage
  },
  {
    path: 'tableaupublic/:id',
    component: TableauPublicPage
  },
  {
    path: 'admin', component: AdminPage
  }
];

@NgModule({
	declarations: [
    HomePage,
    CommunityPage,
    AdminPage,
    TableauPublicPage,
    TableauPrivatePage,
    TableauPrivateStudentPage,
    SasPrivatePage
	],
	imports: [
		CommonModule,
		RouterModule.forChild(MODULE_ROUTES),
    SharedModule,
    //NgxTextOverflowClampModule
	],
  exports: [
    HomePage,
    AdminPage,
    TableauPublicPage,
    TableauPrivatePage,
    TableauPrivateStudentPage,
    SasPrivatePage
	]
})
export class HomeModule { }
