import { Component, OnInit, OnDestroy, Directive } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { Observable, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as Auth from '../../../shared/models/auth.model';
import * as Common from '../../../shared/models/common.model';
import { take, tap } from 'rxjs/operators';
import { TextService } from '../../../core/services/text.service';
import * as Text from '../../../shared/models/gen.text.model';
import * as Enums from '../../../shared/models/gen.enums';

@Directive()
@Component({
  selector: 'app-layout-private',
  templateUrl: './layout-private.component.html',
  styles: []
})
export class LayoutPrivateComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  session: Auth.SessionInfo;

  screenTopNotification: string;

  constructor(
    private authSvc: AuthService,
    private textSvc: TextService,
    private router: Router) {
  }

  ngOnInit() {
    this.subscription.add(this.authSvc.sessionSubject
      .subscribe(value => this.session = value));

    // Get screen top notification text
    const filter: Text.GetTextTypeFilter = {
      type: Enums.TextType.Notification
    };
    this.textSvc.getTextByType(filter)
      .pipe(take(1))
      .subscribe(result => {
      if (result) {
        this.screenTopNotification = result.text;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
