import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Category from '../../shared/models/gen.category.model';
import * as Validation from '../../shared/models/gen.validation';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  public baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  // Get

  getCategory(filter: Category.Filter): Observable<Category.View> {
    return this.http.post<Category.View>(
      this.baseUrl + 'api/Category/Get', filter);
  }

  // Save

  saveCategory(detail: Category.Detail): Observable<Category.SaveResult> {
    return this.http.post<Category.SaveResult>(
      this.baseUrl + 'api/Category/Save', detail);
  }

  // Delete

  deleteCategories(detail: Category.DeletionDetail): Observable<Category.DeletionResult> {
    return this.http.post<Category.DeletionResult>(
      this.baseUrl + 'api/Category/Delete', detail);
  }

  // Form

  getCategoryModel(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({

      id: [''],

      name: ['', [
        Validators.required,
        Validators.maxLength(Validation.CategoryName.maxLength)
      ]]

    });
  }

  // Validation

  static validationMsg = {
    name: {
      required: Validation.CategoryName.requiredMessage,
      maxlength: Validation.CategoryName.maxLengthMessage
    }
  };

}
