import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { CanDeactivateGuard } from '../../shared/guards/can-deactivate.guard';

import { TagListPage } from './pages/tag-list/tag-list.page';
import { TagEditPage } from './pages/tag-edit/tag-edit.page';

const MODULE_ROUTES: Routes = [
  {
    path: 'tags/:id',
    component: TagEditPage,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  }, {
    path: 'tags',
    component: TagListPage,
    canActivate: [AuthGuard]
  }
];

@NgModule({
	declarations: [
    TagListPage,
    TagEditPage
	],
	imports: [
		CommonModule,
		RouterModule.forChild(MODULE_ROUTES),
		SharedModule
	],
  exports: [
    TagListPage,
    TagEditPage
	]
})
export class TagsModule { }
