import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Common from '../../shared/models/common.model';
import * as User from '../../shared/models/gen.user.model';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public baseUrl: string;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getUser(filter: User.Filter): Observable<User.View> {
    return this.http.post<User.View>(
      this.baseUrl + 'api/User/Get', filter);
  }

  saveUser(detail: User.Detail): Observable<User.SaveResult> {
    return this.http.post<User.SaveResult>(
      this.baseUrl + 'api/User/Save', detail);
  }

  toggleActive(detail: Common.IdActive): Observable<User.ActiveResult> {
    return this.http.post<User.ActiveResult>( 
      this.baseUrl + 'api/User/ToggleActive', detail);
  }

  getUserModel(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      id: [''],
      userName: [''],
      displayName: [''],
      isActive: [''],
      isActiveAsString: [''],
      isAdmin: [''],
      isAdminAsString: [''],
      tableauUserId: [''],
      studentTableauUserId: [''],
      sasUserId: [''],
      dateLastLogin: ['']
    });
  }

  static validationMsg = {
  }; 

}
