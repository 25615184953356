import { Component, OnInit, HostListener, Directive } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormControl } from '@angular/forms';

import { of, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AuthService } from '../../../../core/services/auth.service';
import { AlertService } from '../../../../core/services/alert.service';
import { ButtonState } from '../../../../shared/models/ui.model';

import * as Common from '../../../../shared/models/common.model';
import * as Enums from '../../../../shared/models/gen.enums';
import * as Model from '../../../../shared/models/gen.report.model';
import { ReportService } from '../../../../core/services/report.service';


@Directive()
@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.page.html'
})
export class ReportEditPage implements OnInit, Common.CanComponentDeactivate {

  thisThingLCS = "report";

  saveBtn: ButtonState = {
    iconClass: "fad fa-save" 
  };
  runBtn: ButtonState = {
    iconClass: "fad fa-play"
  };
  isReadOnly = false;
  showOverrideFields = false;

  allReportTypes = Enums.ReportType;
  
  model: UntypedFormGroup;
  view: Model.View;
  detail: Model.Detail;
  saveResult: Model.SaveResult;
  categorySaveResult: Model.CategorySaveResult;

  reportType: Model.ReportType;
  allTags: Model.Tag[];
  allCategories: Model.Category[];
  showOverrides: true;

  newTag = "";
  newCategory = "";

  loaded = false;

  routeInfo: {
    id: string;
  };

  isNew: boolean;
  
  validationMsg = ReportService.validationMsg;

  saveMsg = {
    invalid: "Please fix the errors on this page before saving",
    success: "The test was saved successfully",
    error: "An error occurred while saving the test"
  };

  constructor(private fb: UntypedFormBuilder,
    private authSvc: AuthService,
    private svc: ReportService,
    private alertSvc: AlertService,
    private route: ActivatedRoute,
    private router: Router)
  { }

  ngOnInit() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => of({
        id: params.get('id')
      }))
    )
      .subscribe(info => {
      this.routeInfo = info;

      const filter: Model.Filter = {
        id: info.id
      };
        this.svc.getReport(filter)
          .pipe(take(1))
          .subscribe(result => {
        this.view = result;
        this.detail = result.detail;

        this.allTags = result.allTags;
        this.allCategories = result.allCategories;

        // If we're making a copy, update the detail using the prior values.
        if (history.state.copyDetail) {
          this.detail = $.extend(true, {}, history.state.copyDetail);
          this.detail.id = null;
          if (this.detail.title)
            this.detail.title = this.detail.title + " - Copy";
        }

        this.isNew = this.detail.id === null;

        this.model = this.svc.getReportModel(this.fb);
        this.model.patchValue(this.detail);

        // If this is a copy, mark the form as dirty so the Save button is enabled.
        if (history.state.copyDetail)
          this.model.markAsDirty();

        this.showOverrideFields =
          this.detail.reportTypeId == Enums.ReportType.TableauPrivate
          || this.detail.reportTypeId == Enums.ReportType.TableauPrivateStudent
          || this.detail.reportTypeId == Enums.ReportType.SASPrivate;

        this.loaded = true;
        
      }, error => {
          this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
        console.error(error);
      });

    }, error => {
      this.alertSvc.showError("An error occurred while loading the " + this.thisThingLCS);
      console.error(error);
    });

  }
  //

  get f() { return this.model.controls; }

  //

  addTag() {
    var tagDetail: Model.TagDetail = {
      newTag: {
        id: 0,
        name: this.newTag,
      }
    }

    this.svc.addTag(tagDetail)
      .pipe(take(1))
      .subscribe(result => {

      this.allTags = result.allTags;
      this.model.controls.selectedTags.value.push(result.detail.newTag.id);
      this.newTag = "";
      
      // Although the tag was created, it hasn't been linked to the report yet
      this.model.markAsDirty();

    }, error => {
      this.alertSvc.showError("An error occurred adding the tag");
      console.error(error);
    });
  }

  //

  addCategory() {
    var categoryDetail: Model.CategoryDetail = {
      newCategory: {
        id: 0,
        name: this.newCategory,
      }
    }

    this.svc.addCategory(categoryDetail)
      .pipe(take(1))
      .subscribe(result => {

      this.allCategories = result.allCategories;
      this.model.controls.selectedCategories.value.push(result.detail.newCategory.id);
      this.newCategory = "";

      // Although the category was created, it hasn't been linked to the report yet
      this.model.markAsDirty();

    }, error => {
      this.alertSvc.showError("An error occurred adding the category");
      console.error(error);
    });
  }

  //

  save() {
    this.model.markAllAsTouched();

    if (!this.model.valid) {
      this.alertSvc.showSubmitInvalid(this.saveBtn, this.saveMsg.invalid);
      return;
    }

    this.alertSvc.showSubmitStart(this.saveBtn);

    this.f.isActive.setValue(true);

    this.svc.saveReport(this.model.value)
      .pipe(take(1))
      .subscribe(result => {
      if (!this.alertSvc.processModelErrors(result.modelErrors, this.model, this.saveBtn, this.saveMsg.invalid)) {
        this.model.markAsPristine();
        this.router.navigateByUrl("/reports");
      }
    }, error => {
      this.alertSvc.showSubmitError(this.saveBtn, this.saveMsg.error);
      console.error(error);
    });
  }

  copy() {
    const extras = {
      state: {
        copyDetail: this.model.value
      }
    };
    this.router.navigateByUrl("/reports/new", extras);
  }

  cancel() {
    this.router.navigateByUrl("/reports");
  }

  //

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.model.dirty)
      return true;
    return this.alertSvc.confirm({
      title: "Leave?",
      content: "Do you want to leave without saving your changes?"
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(e) {
    if (this.model.dirty)
      e.returnValue = "Are you sure you want to leave?";
  }
}
