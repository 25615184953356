import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';


@Component({
  selector: 'app-required-field',
  templateUrl: './required-field.component.html',
  styles: []
})
export class RequiredFieldComponent {
  @Input() field: AbstractControl;

  isMissing(): boolean {
    const result = this.hasRequiredError(this.field);
    return result;
  }

  hasRequiredError(control: any): boolean {
    if (control.controls) {
      let hasError = false;
      $.each(control.controls, (k, c) => {
        hasError = hasError || this.hasRequiredError(c);
      });
      return hasError;
    }
    return control.errors?.required ? true : false;
  }
}
